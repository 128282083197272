import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const headerStyle = {
  letterSpacing: "0.1rem",
  marginBottom: "0.5rem",
  color: "#fff",
};

const vehicleMenu = [
  "RANGE ROVER​",
  "DEFENDER",
  "DISCOVERY",
  "SPECIAL VEHICLE OPERATIONS",
  "FLEET & BUSINESS",
];
const purchaseMenu = [
  "NEW CARS",
  "APPROVED-PREOWNED",
  "FINANCE CALCULATOR",
  "BOOK YOUR TEST DRIVE",
];
const ownerMenu = ["OWNERSHIP SERVICES", "INCONTROL", "SOFTWARE UPDATES"];

const aboutMenu = [
  {
    title: "JAGUAR LAND ROVER",
    link: "https://www.jaguarlandrover.com/?_gl=1*1n3mdxp*_gcl_au*MTgzNjUzODU2OS4xNzAwNjI4MDY4*_ga*MjA2Nzk3MTkxLjE3MDA2MjgwNjk.*_ga_NLKGNV63E4*MTcwMDYyODA2OC4xLjEuMTcwMDYyODY2Mi4wLjAuMA..*_fplc*cUtYUHl4bWV4Yng0dEVUdnhUaGtLWGpOUUNCcGJyR2ZtZ2k3VklkNkMweXA0MmxZaWxrTWVydFhOSFNFd2s0VWwzUUNMcG5rZG1xa1NFNXR1MmtnSmg0RnJNZUVTSzFOeWJ1WTlBSVAwZ3VXRm8lMkJBaURQQlFCOHlMQjlRRkElM0QlM0Q.&_ga=2.168451617.1675643842.1700628104-206797191.1700628069",
  },
  {
    title: "SUSTAINABILITY",
    link: "https://www.jaguarlandrover.com/environment?_gl=1*w41qcb*_gcl_au*MTgzNjUzODU2OS4xNzAwNjI4MDY4*_ga*MjA2Nzk3MTkxLjE3MDA2MjgwNjk.*_ga_NLKGNV63E4*MTcwMDYyODA2OC4xLjEuMTcwMDYyODg2MS4wLjAuMA..*_fplc*cUtYUHl4bWV4Yng0dEVUdnhUaGtLWGpOUUNCcGJyR2ZtZ2k3VklkNkMweXA0MmxZaWxrTWVydFhOSFNFd2s0VWwzUUNMcG5rZG1xa1NFNXR1MmtnSmg0RnJNZUVTSzFOeWJ1WTlBSVAwZ3VXRm8lMkJBaURQQlFCOHlMQjlRRkElM0QlM0Q.&_ga=2.169096737.1675643842.1700628104-206797191.1700628069",
  },
];
const experienceMenu = ["DRIVING EXPERIENCES", "ADVENTURE TRAVEL"];

const Menu = () => {
  const navigate = useNavigate();
  const hanldeHOB = (i) => {
    if (i === 0) {
      sessionStorage.setItem("item_key", "range-rover");
      navigate("/range-rover");
    } else if (i === 1) {
      sessionStorage.setItem("item_key", "defender");
      navigate("/defender");
    } else if (i === 2) {
      sessionStorage.setItem("item_key", "discovery");
      navigate("/discovery");
    } else if (i === 3) {
      window.open(
        "https://www.landrover.co.za/special-vehicle-operations/index.html",
        "_self"
      );
    } else if (i === 4) {
      window.open(
        "https://www.landrover.co.za/fleet-and-business/index.html",
        "_self"
      );
    }
  };

  const handlePurchaseMenu = (i) => {
    if (i === 0) {
      window.open(
        "https://www.landrover.co.za/build-your-own/index.html?per=1",
        "_self"
      );
    } else if (i === 1) {
      window.open("https://www.findmylandrover.co.za/", "_self");
    } else if (i === 2) {
      window.open(
        "https://www.landrover.co.za/offers-and-finance/finance-calculator.html#/models",
        "_self"
      );
    } else if (i === 3) {
      window.open(
        "https://cloud.e.infojaguarlandrover.com/land_rover_test_drive_general?formCode=lr-td-za-en&_gl=1*jirykz*_gcl_au*OTMxMzk5ODg0LjE2OTExNDE1MTM.*_ga*MTUzNjk4MTQ3OC4xNjkxMTQxNTEz*_ga_NLKGNV63E4*MTY5NzIwMjkwNy4xNTkuMS4xNjk3MjAzNDU1LjAuMC4w*_fplc*Nk5EdzQxTGhEMmZLbktwT2pYSWJOdGtXSVRHZUR6VjY5c1A0eDVrSzU3bTdxOGNYaXJVQ1JadnNqblRSTDV3NiUyRjlUYlZKNGhTdFl5OVlxaERJaVMlMkI2YUN0bTU5MDJMMjJhWHRDVUszUk00RDZVTzdrRHM1TFdSeHJicEpyZyUzRCUzRA..&_ga=2.252482825.1090674387.1696828042-1536981478.1691141513",
        "_self"
      );
    }
  };

  const handleOwnerShipMenu = (index) => {
    if (index === 0) {
      window.open("https://www.landrover.co.za/ownership/index.html", "_self");
    } else if (index === 1) {
      window.open(
        "https://www.landrover.co.za/ownership/incontrol/index.html",
        "_self"
      );
    } else if (index === 2) {
      window.open(
        "https://www.landrover.co.za/ownership/software-update/index.html",
        "_self"
      );
    }
  };

  const handleExperienceMenu = (index) => {
    if (index === 0) {
      window.open("https://www.landrover.co.za/experience/index.html", "_self");
    } else if (index === 1) {
      window.open(
        "https://www.landrover.co.za/experience/adventure-travel/index.html",
        "_self"
      );
    }
  };
  return (
    <Grid container spacing={3} className="footer_menu">
      {/* Column 1 */}
      <Grid item xs={12} sm={3}>
        <Stack spacing={1} marginBottom={"1.5rem"}>
          <h6 style={headerStyle}>VEHICLES</h6>
          {vehicleMenu?.length > 0 &&
            vehicleMenu.map((item, index) => {
              return (
                <Box
                  sx={{ marginTop: "0 !important" }}
                  onClick={() => hanldeHOB(index)}
                  key={item}
                >
                  <Link className="nav-menu-links">{item}</Link>
                </Box>
              );
            })}
        </Stack>
        <Stack spacing={1}>
          <h6 style={headerStyle}>PURCHASE</h6>
          {purchaseMenu?.length > 0 &&
            purchaseMenu.map((purchase, index) => {
              return (
                <Box
                  sx={{ marginTop: "0 !important" }}
                  key={index}
                  onClick={() => handlePurchaseMenu(index)}
                >
                  <Link className="nav-menu-links">{purchase}</Link>
                </Box>
              );
            })}
        </Stack>
      </Grid>

      {/* Column 2 */}
      <Grid item xs={12} sm={3}>
        <Stack spacing={1}>
          <h6 style={headerStyle}>OWNERS</h6>

          {ownerMenu?.length > 0 &&
            ownerMenu.map((owner, index) => {
              return (
                <Box
                  sx={{ marginTop: "0 !important" }}
                  key={index}
                  onClick={() => handleOwnerShipMenu(index)}
                >
                  <Link className="nav-menu-links">{owner}</Link>
                </Box>
              );
            })}
        </Stack>
      </Grid>

      {/* Column 3 */}
      <Grid item xs={12} sm={3}>
        <Stack spacing={1} style={{ marginBottom: "1.5rem" }}>
          <h6 style={headerStyle}>EXPERIENCES</h6>
          {experienceMenu?.length > 0 &&
            experienceMenu.map((value, index) => {
              return (
                <Box
                  sx={{ marginTop: "0 !important" }}
                  key={value}
                  onClick={() => handleExperienceMenu(index)}
                >
                  <Link className="nav-menu-links">{value}</Link>
                </Box>
              );
            })}
        </Stack>
        <Stack spacing={1}>
          <h6 style={headerStyle}>ABOUT US</h6>
          {aboutMenu?.length > 0 &&
            aboutMenu.map((about, index) => {
              return (
                <Box sx={{ marginTop: "0 !important" }} key={about.title}>
                  <Link to={about.link} className="nav-menu-links">
                    {about.title}
                  </Link>
                </Box>
              );
            })}
        </Stack>
      </Grid>

      {/* Column 4 */}
      <Grid item xs={12} sm={3}>
        <Stack spacing={1}>
          <h6 style={headerStyle}>JOIN THE CONVERSATION</h6>
          <div className="icon-container">
            <span className="span-logo span-logo-facebook">
              <i className="social-icon fa-brands fa-facebook-f"></i>
            </span>
            <Link
              target="_blank"
              className="nav-menu-links"
              to={"https://www.facebook.com/landrover/"}
            >
              FACEBOOK
            </Link>
          </div>
          <div className="icon-container">
            <span className="span-logo span-logo-twitter">
              <i className="social-icon fa-brands fa-twitter"></i>
            </span>
            <Link
              target="_blank"
              className="nav-menu-links"
              to={"https://twitter.com/LandRover"}
            >
              TWITTER
            </Link>
          </div>
          <div className="icon-container">
            <span className="span-logo span-logo-youtube">
              <i className="social-icon fa-brands fa-youtube"></i>
            </span>
            <Link
              target="_blank"
              className="nav-menu-links"
              to={"https://www.youtube.com/landroversouthafrica"}
            >
              YOUTUBE
            </Link>
          </div>
          <div className="icon-container">
            <span className="span-logo span-logo-instagram">
              <i className="social-icon fa-brands fa-instagram"></i>
            </span>
            <Link
              target="_blank"
              className="nav-menu-links"
              to={"https://www.instagram.com/landrover/"}
            >
              INSTAGRAM
            </Link>
          </div>
          <div className="icon-container">
            <span className="span-logo span-logo-tiktok">
              <i className="social-icon fa-brands fa-tiktok"></i>
            </span>
            <Link
              target="_blank"
              className="nav-menu-links"
              to={"https://www.tiktok.com/@rangerover?lang=en"}
            >
              TIKTOK
            </Link>
          </div>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Menu;
