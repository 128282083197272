import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  Typography,
  Stack,
  Pagination,
} from "@mui/material";
import { FiCheck } from "react-icons/fi";
import styles from "./VehicleCard.module.css";
import { BsArrowRight } from "react-icons/bs";
import ExploreButton from "../ExploreButton";
import RepresentativePopup from "../Popup/RepresentativePopup";
import { json, useNavigate } from "react-router-dom";
import DescriptionPopup from "@components/Popup/DescriptionPopup";
import image2 from "@assets/images/Discovery-list-image1.avif";
import { MdKeyboardArrowRight } from "react-icons/md";
import StepperPopup from "@components/Popup/StepperPopup";
import { useDispatch, useSelector } from "react-redux";
import { getSavedVehicles, submitOrder } from "@redux/vehicleList";
import { PiEngineDuotone } from "react-icons/pi";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { numberWithCommas } from "@utils/Constant";
import cardImage from "@assets/images/no-image-stock.jpeg";
import OptionPopup from "@components/Popup/OptionPopup";

const vehicleData = [
  {
    image:
      "https://imagegen.config.landrover.com/ciw/img?z=eAGVlUtzmzAUhX-N2cGIR9x0wUKNa6et0zaPTh8bjQAZEsuIAg1Mfn3vFUK2u4k6YyHZzPl0jqQrS5JWfd90i5guojV8eNeJvgtyVe8ey0DyumjVs2jhhwO8lW2Ez2RJoNtH-EWM-x10WanHWck27JpxvxsyFgZPTbmI18-LeJXwJDx4MiSpmehdcBWsgvegwmk30K6hfYCWK6n-tDAQY9YJzdsisS_g2RdLP74grPZDzlnXxKyU7Cb47HEX9JeZeuDd3pBv2R17YN8A0tTnbnNX5NdzVPDUqfqf3Bev5x4qIaQ2OFRSNb2G1j6JCKzqlqFPdIlRHWg_T0g2LaUnoO9e7gr6pb0c1cEPT0YOW5nxohTTmuNIU-bVhhwuCHp11NscMwMiODPo6jg9-o8d_NtF1FLcA9iPOHwR5sRAhv_C2ACIohtDgRSuFLq2TiY5JkkckrRKYZmKEQeaAUkuCc8xURLmIxyMe6ivRhXzKXPBUizYCWqz0U-MbjWO3mgW5HNm0Y_a3DkDQ4bEISVUzSOUHlqaC4j7pYRLDfxMRa5vCgcUne4KwByD3VoK1o6LIU2h0_1AjVwXz6WrBSPGcxcdemsAq8edcTct6r2VY-G4y41_I9f-30avX2in4cH_MvldWwPo351h_D9YOfp3lxv_Rg7-hxR2j3jV1O1S4qkU_qu8cUjfxEtvrNIkTP4CmyHsRQ~~",
    title: "DISCOVERY METROPOLITAN EDITION",
    modle: "LAND ROVER DISCOVERY",
    date: "05-05-2023 11:32:22",
    orderNumber: "BUILD149",
    colorName: "Portofino Blue",
    orderStage: " Confirm Order",
    orderStatus: "New",
    feature: [
      "Advanced Off-Road Capa.",
      "Twin-speed transfer bo.",
      "All Terrain Progress C..",
      "Advanced Off-Road Capa.",
      "Twin-speed transfer bo.",
      "Advanced Off-Road Capa.",
    ],
    amount: " 1,957,900",
    monthly: "23,546 ",
  },
  {
    image:
      "https://imagegen.config.landrover.com/ciw/img?z=eAGVlUGTmjAYhn-N3GACRG0PHNJ1V9u629W1nbaXTIAIrllCARe2v75fAkTtpekMIRHme_I-CRkFivKmKetJSCbBHVysrnlTe4ks9ofME6xIK_nKK3jwAm9FFaj7dOpDdwzCKXS8O-6hizM9jjO6pCvK3LqNqe89l9kkvHudhAvMcFE4wkfRMNMH78ZbeLdQpeZdQltB-wgtkUKeKhjwLq655q2ByE6NjA8yq1iZv8HvNEDYhcdtSgvXZ3u4ozkWhctiClcm6L334DCbGb-Mk72w-jhMuKFbuqNf6TfAlMW1RmILffwb5j3XsrheksBiSWKWZrwPqUaauoVwOx0PLG0gP88A43mGfHcSWwghlwG8H44I3v97W9ucc6EjtLmQZaMZsGcBijld041RsWCRmwuQkSG3hqNsbDlkoaOM1contNiTcwZdrgzAJvTLEjZmO9r8H8moKBpZGRDoWIPI0uQZCUoJWyhVUqqzzDs1GPfnHWKJMsN-8hvW9wmOXinT0c8GSz4ZqBEka0ruNY48aBYoWrPI537HrhhKcmYhCZ_eAc6g0rz4Cmf4VwJx-vMOB8qGRB5HyllrayAgZA0hm15oqFYqcwsVHWCoZW4jT1nOC56aCOBhj3nqI-xMNQjYVw8CQzUItJGPEHLyvttHyJER_B04XRvNw5nT5RH28R_eycof",
    title: "DISCOVERY METROPOLITAN EDITION",
    modle: "LAND ROVER DISCOVERY",
    date: "05-05-2023 11:32:22",
    orderNumber: "BUILD149",
    colorName: "Portofino Blue",
    orderStage: " Confirm Order",
    orderStatus: "New",
    feature: [
      "Advanced Off-Road Capa.",
      "Twin-speed transfer bo.",
      "All Terrain Progress C..",
      "Advanced Off-Road Capa.",
      "Twin-speed transfer bo.",
      "Advanced Off-Road Capa.",
    ],
    amount: " 1,957,900",
    monthly: "23,546 ",
  },
  {
    image: image2,
    title: "DISCOVERY METROPOLITAN EDITION",
    modle: "LAND ROVER DISCOVERY",
    date: "05-05-2023 11:32:22",
    orderNumber: "BUILD149",
    colorName: "Portofino Blue",
    orderStage: " Confirm Order",
    orderStatus: "New",
    feature: [
      "Advanced Off-Road Capa.",
      "Twin-speed transfer bo.",
      "All Terrain Progress C..",
      "Advanced Off-Road Capa.",
      "Twin-speed transfer bo.",
      "Advanced Off-Road Capa.",
    ],
    amount: " 1,957,900",
    monthly: "23,546 ",
  },
];

const VehicleCard = ({ carData, type }) => {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [orderPopup, setOrderPopup] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const [optionData, setOptionData] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClose = () => {
    setOpen(false);
    setOrderPopup(false);
    setIsReadMore(false);
  };

  const handleOpenModal = (data) => {
    setIsReadMore(true);
    setOptionData(data);
  };
  const handleSubmit = (data) => {
    let datanew = {
      nameplate: data?.data?.original.nameplate,
      model: data?.data?.original.model,
      thumb: data?.data?.original.thumb,
      color: data?.data?.original.color,
      colorCode: data?.data?.original.colorCode,
      price: data?.data?.original.price,
      thumb_image: data?.data?.original.thumb_image,
    };
    let obj = {
      user_id: data?.customer_id,
      data: JSON.stringify(datanew),
      brand: "JAG",
    };
    dispatch(submitOrder(obj));
  };
  return (
    <>
      {/* <Typography sx={{ marginBottom: "25px" }} variant="h6">
        {type === "vehicle" ? "MY VEHICLE ORDERS" : "MY SAVED BUILDS"}
      </Typography> */}
      {/* {vehicleData?.length > 0 ? (
        <div p={2} className={styles.grid}>
          {vehicleData.map((item, index) => {
            return (
              <Card
                sx={{ maxWidth: "100%", borderRadius: "0" }}
                key={item.id}
                elevation={4}
              >
                <CardMedia
                  className={styles.card_image}
                  component="img"
                  image={item.image}
                  alt="image"
                />
                <CardContent>
                  <Typography
                    variant="h5"
                    fontSize={"16px"}
                    component="div"
                    className={styles.mobile_responsive}
                  >
                    {item.title}
                  </Typography>
                  <p className={styles.mobile_responsive}>{item.modle}</p>
                  <p className={styles.mobile_responsive}>{item.date}</p>

                  <Grid item xs={12} lg={12} className={styles.engine_year}>
                    <Box>
                      {type === "vehicle" && (
                        <>
                          <Typography fontSize={"10px"} gap={0.5}>
                            <span>Order Number -</span>

                            <span>{item.orderNumber}</span>
                          </Typography>
                          <Typography fontSize={"10px"} gap={0.5}>
                            <span>Order Stage -</span>

                            <span>{item.orderStage}</span>
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Box>
                      {type === "vehicle" && (
                        <>
                          {" "}
                          <Typography fontSize={"10px"} gap={0.5}>
                            <span>Color Name -</span>

                            <span>{item.colorName}</span>
                          </Typography>
                          <Typography fontSize={"10px"} gap={0.5}>
                            <span>Color Status - </span>
                            <span>{item.orderStatus}</span>
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Grid>

                  {type !== "vehicle" && (
                    <Grid item xs={12} lg={12} className={styles.engine_year}>
                      <Box>
                        {type !== "vehicle" && (
                          <Typography
                            fontSize={"10px"}
                            variant="h5"
                            sx={{ marginLeft: "-5px" }}
                            className={styles.mobile_responsive_engine}
                            onClick={() => navigate("/finance-assistant")}
                          >
                            <MdKeyboardArrowRight
                              style={{ marginBottom: "2px" }}
                              size={17}
                            />
                            GET A FINANCE QUOTE
                          </Typography>
                        )}
                        <Typography fontSize={"10px"} gap={0.5}>
                          <span>Acceleration (secs) 0‑100 km/h - 8.5</span>
                        </Typography>
                        <Typography fontSize={"10px"} gap={0.5}>
                          <span>Combined NEDC Equivalent (NEDC2)</span>
                        </Typography>
                        <Typography fontSize={"10px"} gap={0.5}>
                          <span>Consumption - l/100km - TBC±</span>
                        </Typography>
                      </Box>
                      <Box>
                        {type !== "vehicle" && (
                          <Typography
                            fontSize={"10px"}
                            variant="h6"
                            sx={{ marginLeft: "-70px" }}
                            className={styles.mobile_responsive_engine}
                            onClick={() => navigate("/book-a-test-drive")}
                          >
                            <MdKeyboardArrowRight
                              style={{ marginBottom: "2px" }}
                              size={17}
                            />
                            BOOK A TEST DRIVE
                          </Typography>
                        )}
                        <Typography fontSize={"10px"} gap={0.5}>
                          <span>Maximum power (PS/kW/rpm) - 199/146/3,750</span>
                        </Typography>
                        <Typography fontSize={"10px"} gap={0.5}>
                          <span>Combined NEDC Equivalent (NEDC2)</span>
                        </Typography>
                        <Typography fontSize={"10px"} gap={0.5}>
                          <span>CO₂ Emissions - g/km - TBC±</span>
                        </Typography>
                      </Box>
                    </Grid>
                  )}

                  {type === "vehicle" && (
                    <p
                      className={styles.order_process}
                      onClick={() => setOrderPopup(true)}
                    >
                      <MdKeyboardArrowRight
                        style={{ marginBottom: "2px" }}
                        size={17}
                      />
                      Order Process
                    </p>
                  )}
                  {type !== "vehicle" && (
                    <hr className={styles.horizantol_line} />
                  )}
                  <p>Features</p>
                  <Grid item xs={12} lg={12} className={styles.feature_content}>
                    {item.feature?.length > 0 ? (
                      item.feature.slice(0, 5).map((value, index) => {
                        return (
                          value !== "" && (
                            <ul key={value}>
                              <span className={styles.vehicle_list_data}>
                                <FiCheck className={styles.check_button} />
                                <span className={styles.list_data}>
                                  {value}
                                </span>
                              </span>
                            </ul>
                          )
                        );
                      })
                    ) : (
                      <p className={styles.no_data}>No feature's found</p>
                    )}
                    {item.feature?.length > 5 && (
                      <p
                        className={styles.read_more_button}
                        onClick={() => handleOpenModal(item.feature)}
                      >
                        Read More
                      </p>
                    )}
                  </Grid>
                  <hr className={styles.horizantol_line} />
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12} lg={8}>
                      <p className={styles.amount}>
                        R &nbsp;
                        {item.amount}
                      </p>
                      <p>
                        R &nbsp;
                        {item.monthly}
                      </p>
                    </Grid>
                    {type !== "vehicle" && (
                      <Grid
                        item
                        xs={12}
                        lg={4}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <ExploreButton
                          className={styles.detail_button}
                          style={{
                            ...btnStyle,
                            ...(window.innerWidth <= 600
                              ? buttonStyleForSmallDevice
                              : {}),
                          }}
                        >
                          <BsArrowRight size={15} />{" "}
                          <span className={styles.mobile_responsive_detail}>
                            SUBMIT ORDER
                          </span>
                        </ExploreButton>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
        </div>
       
      ) 
      : (
        <Typography variant="paragraph" className={styles.no_data}>
          No data found
        </Typography>
      )} */}
      <Grid container spacing={6}>
        {carData?.length ? (
          carData?.map((item, index) => {
            return (
              <Grid item xs={12} lg={6} md={6} key={index}>
                <Card sx={{ borderRadius: "0" }} key={item.id} elevation={0}>
                  <CardMedia
                    className={styles.card_image}
                    component="img"
                    image={
                      item.data?.original.thumb_image
                        ? item.data.original.thumb_image
                        : cardImage
                    }
                    alt="image"
                  />
                  <Grid container>
                    <Grid xs={12} lg={12} md={12} marginTop={2}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        fontSize={"16px"}
                        fontFamily={"LandRoverOt4-medium"}
                        className={styles.mobile_responsive}
                      >
                        {item.data?.original.nameplate}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontSize={"13px"}
                        color="#494e53"
                        fontFamily={"AvenirNextRegular"}
                        className={styles.mobile_responsive}
                      >
                        {item.data?.original.model}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontSize={"13px"}
                        color="#494e53"
                        fontFamily={"AvenirNextRegular"}
                        className={styles.mobile_responsive}
                      >
                        {item.data?.original.color}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        mt={2}
                      >
                        <Typography
                          fontSize={"10px"}
                          gap={0.5}
                          className={styles.mobile_responsive_engine}
                          onClick={() => navigate("/finance-assistant")}
                        >
                          <IoIosArrowForward
                            size={16}
                            className={styles.show_icon}
                          />
                          <span className={styles.engine_year_font}>
                            GET A FIANNCE QUOTE
                          </span>
                        </Typography>
                        <Typography
                          fontSize={"10px"}
                          gap={0.5}
                          className={styles.mobile_responsive_engine}
                          onClick={() => navigate("/book-a-test-drive")}
                        >
                          <IoIosArrowForward
                            size={16}
                            className={styles.show_icon}
                          />
                          <span className={styles.engine_year_font}>
                            BOOK A TEST DRIVE
                          </span>
                        </Typography>
                      </Box>
                      <Box className={styles.engine_year} mt={2}>
                        <Typography
                          fontSize={"10px"}
                          gap={0.5}
                          className={styles.mobile_responsive_engine}
                        >
                          <span className={styles.engine_year_font}>
                            Acceleration (secs) 0‑100 km/h -
                          </span>
                          &nbsp;
                          <span className={styles.mobile_responsive_engine}>
                            {item.data?.original.accelerationData}
                          </span>
                        </Typography>
                        {/* <Typography
                          fontSize={"10px"}
                          gap={0.5}
                          className={styles.mobile_responsive_engine}
                        >
                          <span className={styles.engine_year_font}>
                            Color Name -
                          </span>
                          &nbsp;
                          <span className={styles.mobile_responsive_engine}>
                            {item.data?.original.color}
                          </span>
                        </Typography> */}
                      </Box>
                      <Box className={styles.engine_year} mt={1}>
                        <Typography
                          fontSize={"10px"}
                          gap={0.5}
                          className={styles.mobile_responsive_engine}
                        >
                          <span className={styles.engine_year_font}>
                            Maximum power (PS/kW/rpm) -
                          </span>
                          &nbsp;
                          <span className={styles.mobile_responsive_engine}>
                            {item.data?.original.maximumPower}
                          </span>
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Typography
                          fontSize={"10px"}
                          gap={0.5}
                          className={styles.mobile_responsive_engine}
                        >
                          <span className={styles.engine_year_font}>
                            Combined NEDC Equivalent (NEDC2) Consumption -
                            l/100km -
                          </span>
                          &nbsp;
                          <span className={styles.mobile_responsive_engine}>
                            {item.data?.original.nedcConsuptionCombined}
                          </span>
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Typography
                          fontSize={"10px"}
                          gap={0.5}
                          className={styles.mobile_responsive_engine}
                        >
                          <span className={styles.engine_year_font}>
                            Combined NEDC Equivalent (NEDC2) CO₂ Emissions -
                            g/km -
                          </span>
                          &nbsp;
                          <span className={styles.mobile_responsive_engine}>
                            {item.data?.original.nedcCo2EmissionsCombined}
                          </span>
                        </Typography>
                      </Box>
                      <Box mt={2}>
                        {item.data?.original.options?.length > 0 ? (
                          item.data.original.options
                            .slice(0, 5)
                            .map((value, index) => {
                              return (
                                value !== "" && (
                                  <ul key={value.id}>
                                    <span className={styles.vehicle_list_data}>
                                      <FiCheck
                                        className={styles.check_button}
                                      />
                                      <span className={styles.list_data}>
                                        {value.name} -
                                      </span>
                                      <span className={styles.list_data}>
                                        {value.price}
                                      </span>
                                    </span>
                                  </ul>
                                )
                              );
                            })
                        ) : (
                          <p className={styles.no_data}>
                            No added option found
                          </p>
                        )}
                        {item.data?.original.options?.length > 2 && (
                          <p
                            className={styles.read_more_button}
                            onClick={() =>
                              handleOpenModal(item.data?.original.options)
                            }
                          >
                            Read More
                          </p>
                        )}
                      </Box>
                    </Grid>
                    {/* <Grid
                        item
                        xs={12}
                        lg={6}
                        md={6}
                        marginTop={2}
                        className={styles.feature_content}
                      >
                        {item.vehicle_data.options?.length > 0 ? (
                          item.vehicle_data.options
                            .slice(0, 5)
                            .map((value, index) => {
                              return (
                                value !== "" && (
                                  <ul key={value.id}>
                                    <span className={styles.vehicle_list_data}>
                                      <FiCheck
                                        className={styles.check_button}
                                      />
                                      <span className={styles.list_data}>
                                        {value.name}
                                      </span>
                                      <span className={styles.list_data}>
                                        {value.price}
                                      </span>
                                    </span>
                                  </ul>
                                )
                              );
                            })
                        ) : (
                          <p className={styles.no_data}>No feature's found</p>
                        )}

                        {item.main_feature?.length > 5 && (
                          <p
                            className={styles.read_more_button}
                            onClick={() => handleOpenModal(item.main_feature)}
                          >
                            Read More
                          </p>
                        )}
                        {item.vista_catalogue?.length > 5 && (
                          <p
                            className={styles.read_more_button}
                            onClick={() =>
                              handleOpenModal(item.vista_catalogue)
                            }
                          >
                            Read More
                          </p>
                        )}
                      </Grid> */}
                  </Grid>
                  <hr className={styles.horizantol_line} />
                  <Grid container className={styles.button_flex}>
                    <Box>
                      {/* <Grid item xs={12} lg={6} md={6}> */}
                      <Typography fontSize={"18px"} fontFamily={"LandRoverWeb"}>
                        R&ensp;
                        {item.data?.original.monthly_price
                          ? numberWithCommas(
                              item.data?.original.monthly_price.replace(
                                /,/g,
                                ""
                              )
                            )
                          : "0"}
                        &ensp;p/m
                      </Typography>
                      <Typography
                        color={"#4a4f54"}
                        fontSize={"18px"}
                        fontFamily={"LandRoverWeb"}
                      >
                        R&ensp;
                        {item.data?.original.price
                          ? numberWithCommas(
                              item.data.original.price.replace(/,/g, "")
                            )
                          : "0"}
                      </Typography>
                    </Box>
                    {/* </Grid> */}
                    <Box>
                      {/* <Grid item xs={12} lg={6} md={6}> */}
                      <ExploreButton
                        className={styles.detail_button}
                        style={{
                          ...btnStyle,
                          ...(window.innerWidth <= 600
                            ? buttonStyleForSmallDevice
                            : {}),
                        }}
                        onClick={() => handleSubmit(item)}
                      >
                        <BsArrowRight size={15} />
                        <span className={styles.mobile_responsive_detail}>
                          SUBMIT FOR ORDER
                        </span>
                      </ExploreButton>
                    </Box>
                    {/* </Grid> */}
                    <hr className={styles.horizantol_line} />
                  </Grid>
                </Card>
              </Grid>
            );
          })
        ) : (
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography>No data found</Typography>
          </Grid>
        )}
      </Grid>
      {/* <Stack spacing={2} display={"flex"} alignItems={"center"} mt={2}>
        <Pagination
          sx={{ justifyContent: "center" }}
          count={carData?.length}
          page={page}
          onChange={handleChangePage}
          size="large"
        />
      </Stack> */}
      {isReadMore && (
        <OptionPopup
          open={isReadMore}
          close={handleClose}
          optionData={optionData}
        />
      )}

      {orderPopup && <StepperPopup open={orderPopup} close={handleClose} />}
    </>
  );
};

const buttonStyleForSmallDevice = {
  height: "24%",
  width: "24%",
};

const btnStyle = {
  fontSize: "0.7rem",
  fontWeight: "600",
  letterSpacing: "0.2em",
};

export default VehicleCard;
