import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { authSlice } from "./authentication";
import rootReducer from "./rootReducers";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

// import {applyMiddleware, compose, createStore} from 'redux';
// import rootReducer from './rootReducers';
// import createSagaMiddleware from 'redux-saga';
// import rootSaga from './saga';

// const sagaMiddleware = createSagaMiddleware();

// function configureStore(initialState) {
//   const middleware = [sagaMiddleware];

//   const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//   const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));

//   sagaMiddleware.run(rootSaga);

//   return store;
// }
// export default configureStore;
