import React from "react";
import { Link } from "react-router-dom";
import image from "@assets/images/SOUTH_AFRICA_ZA.webp";
import { Box } from "@mui/material";

const Navbar = () => {
  return (
    <>
      <nav>
        <ul className="list-group">
          <Box sx={{ display: "flex" }}>
            <img src={image} className="country_logo" alt="country" />
            <span className="country_logo_title">SOUTH AFRICA</span>
          </Box>

          <li className="footer-list-item">
            <Link to={"https://www.jaguarlandrovercareers.com/"}>CAREERS</Link>
          </li>
          <li className="footer-list-item">
            <Link
              to={
                "https://www.landrover.co.za/terms-and-conditions.html?_gl=1*jvjwxy*_ga*MTkzODA0OTQ2LjE2OTc3OTMwNjA.*_ga_NLKGNV63E4*MTY5ODY2NzI0MS4xODkuMS4xNjk4NjY3NTM5LjAuMC4w"
              }
            >
              TERMS & CONDITIONS
            </Link>
          </li>
          <li className="footer-list-item">
            <Link
              to={"https://www.landrover.co.za/ownership/contact-us/index.html"}
            >
              CONTACT US
            </Link>
          </li>
          <li className="footer-list-item">
            <Link to={"https://www.landrover.co.za/data-protection.html"}>
              DATA PROTECTION
            </Link>
          </li>
          <li className="footer-list-item">
            <Link to={"https://www.landrover.co.za/site_map.html"}>
              SITEMAP
            </Link>
          </li>
        </ul>
        <hr className="footer_row" />
      </nav>
    </>
  );
};

export default Navbar;
