import Navbar from "@components/NavMenu";
import { Box, Typography } from "@mui/material";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const OrderCancel = () => {
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <Navbar navLogo={""} />
      <Box padding={8} paddingBottom={12}>
        <Typography
          variant="h5"
          sx={{
            marginLeft: "12px",
            // fontSize: "24px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Order Cancelled
        </Typography>
        <Box mt={3}>
          <Typography
            variant="h5"
            sx={{ marginLeft: "12px", textAlign: "center" }}
          >
            Your order been been cancelled!
          </Typography>
        </Box>
        <Box
          mt={1}
          padding={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <span style={{ fontSize: "16px", lineHeight: "2rem" }}>
            You have successfully cancelled the payment on your order.
            <br />
            You can welcome to{" "}
            <NavLink style={{ fontSize: "14px" }} to={"/"}>
              return to the store
            </NavLink>{" "}
            and continue shopping whenever you are ready.
            <br />
            For inquiry, please contact our{" "}
            <NavLink style={{ fontSize: "14px" }} to={"/contact-us"}>
              customer support team
            </NavLink>
            <br />
            Thanks for visiting us online!.
          </span>
        </Box>
        <Box display={"flex"} justifyContent={"center"} mt={2}>
          <button style={{ width: "45%" }} onClick={() => navigate("/")}>
            Continue
          </button>
        </Box>
      </Box>
    </div>
  );
};

export default OrderCancel;
