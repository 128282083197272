import authentication from "./authentication";
import vehicleList from "./vehicleList";
const rootReducer={
  authentication,
  vehicleList
}

export default rootReducer;

// import { combineReducers } from 'redux';
// import ToastReducer from "./reducers/ToastReducer";

// const rootReducer = combineReducers({
//   toastReducer: ToastReducer,  
// });

// export default rootReducer;

