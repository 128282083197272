import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postReq, getReq } from "@utils/ApiHandler";
import { toast } from "react-toastify";

export const loginDetails = createAsyncThunk(
  "authentication/loginDetail",
  async (form) => {
    try {
      const response = await postReq("auth", form);
      if (response.data) {
        return response.data;
      } else {
        toast.error(response.error.error);
      }
      // return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const registerDetails = createAsyncThunk(
  "authentication/register",
  async (obj) => {
    let data = new FormData();
    data.append("first_name", obj.first_name);
    data.append("last_name", obj.last_name);
    data.append("email_address", obj.email_address);
    data.append("password", obj.password);
    data.append("contact_number", obj.contact_number);
    data.append("is_terms", obj.is_terms);
    data.append("preferred_dealer", obj.preferred_dealer);
    data.append("vehicle_model", obj.vehicle_model);
    data.append("brand", "LR");
    data.append("type", "");
    data.append("socialId", "");
    data.append("consent_email", true);
    data.append("consent_phone", true);
    try {
      const response = await postReq("auth/register", data);

      if (response.data) {
        return response.data;
      } else {
        toast.error(response.error.errors);
      }
    } catch (error) {
      console.log(error, "error");
    }
  }
);

export const ChangePassword = createAsyncThunk(
  "authentication/changePassword",
  async (form) => {
    try {
      const response = await postReq("change-password", form);

      if (response.data) {
        return toast.success(response.data.message);
      } else {
        toast.error(response.error.errors);
      }
      // return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "authentication/resetPassword",
  async (form) => {
    try {
      const response = await postReq("resetpassword ", form);
      if (response.data) {
        return response.data;
      } else {
        toast.error(response.error.error);
      }
      // return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "authentication/forgotPassword",
  async (form) => {
    try {
      const response = await postReq("forgotPassword", form);
      if (response.data) {
        return response.data;
      } else {
        toast.error(response.error.error);
      }
      // return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const ProfileUpdate = createAsyncThunk(
  "authentication/profileUpdate",
  async (form) => {
    try {
      const response = await postReq("profileUpdate", form);

      if (response.data) {
        sessionStorage.setItem(
          "userDetails",
          JSON.stringify(response.data.data)
        );
        toast.success(response.data.message);
      } else {
        toast.error(response.error.errors);
      }
      // return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const quoteLeadForm = createAsyncThunk(
  "authentication/quote",
  async (obj) => {
    try {
      const response = await postReq("detailQuote", obj);
      if (response.data) {
        return response.data;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error, "error");
    }
  }
);

export const financeLeadForm = createAsyncThunk(
  "authentication/finance",
  async (obj) => {
    try {
      const response = await postReq("detailFinance", obj);
      if (response.data) {
        return response.data;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error, "error");
    }
  }
);

export const contactLeadForm = createAsyncThunk(
  "authentication/contact",
  async (obj) => {
    try {
      const response = await postReq("contactUs", obj);
      if (response.data) {
        return response.data;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error, "error");
    }
  }
);
export const testDriveLeadForm = createAsyncThunk(
  "authentication/testDrive",
  async (obj) => {
    try {
      const response = await postReq("detailTestDrive", obj);
      if (response.data) {
        return response.data;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error, "error");
    }
  }
);
//getDealers
export const getDealers = createAsyncThunk(
  "authentication/dealers",
  async (form) => {
    try {
      const response = await getReq("getDealers");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
//nameplates
export const getNameplates = createAsyncThunk(
  "authentication/nameplates",
  async (form) => {
    try {
      const response = await getReq("nameplates/LR");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
const initialState = {
  data: "",
  loginData: "",
  isLoading: false,
  dealersData: [],
  nameplates: [],
};

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    increment: (state) => {
      state.value = state.value + 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginDetails.fulfilled, (state, action) => {
      state.loginData = action.payload;
      state.isLoading = false;
      if (action.payload === null) {
        toast.error(action.error);
      } else {
        toast.success(action?.payload?.message);
        state.isLoading = false;
      }
    });
    builder.addCase(loginDetails.rejected, (state, action) => {
      state.isLoading = false;
    });
    //Register
    builder.addCase(registerDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(registerDetails.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      if (action?.payload?.status === 1) {
        toast.success(action.payload.message);
      } else if (action?.payload === undefined) {
        // toast.error(action.payload.message);
        state.isLoading = false;
      } else {
        toast.error(action.payload.message);
        state.isLoading = false;
      }
    });
    builder.addCase(registerDetails.rejected, (state, action) => {
      state.isLoading = false;
    });

    //change-password
    builder.addCase(ChangePassword.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(ChangePassword.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(ChangePassword.rejected, (state, action) => {
      state.isLoading = false;
    });
    //reset-password
    builder.addCase(resetPassword.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isLoading = false;
    });
    //forgot-password
    builder.addCase(forgotPassword.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.isLoading = false;
    });

    //profile Update
    builder.addCase(ProfileUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(ProfileUpdate.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(ProfileUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });
    //lead form
    builder.addCase(quoteLeadForm.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(quoteLeadForm.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      if (action.payload.status == 1) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(quoteLeadForm.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(financeLeadForm.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(financeLeadForm.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      if (action.payload.status == 1) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(financeLeadForm.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(testDriveLeadForm.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(testDriveLeadForm.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      if (action.payload.status == 1) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(testDriveLeadForm.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(contactLeadForm.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(contactLeadForm.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      if (action.payload.status == 1) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(contactLeadForm.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getDealers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getDealers.fulfilled, (state, action) => {
      state.dealersData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getDealers.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getNameplates.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getNameplates.fulfilled, (state, action) => {
      state.nameplates = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getNameplates.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { increment } = authSlice.actions;

export default authSlice.reducer;
