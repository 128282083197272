import image4 from "../assets/images/RangeRoverWhiteimg.webp";
import cardImage from "../assets/images/card-image1.avif";
import defenderImage from "../assets/images/Defender-list-image.avif";
import defenderImage1 from "../assets/images/Defender-list-image1.avif";
import discoveryImage from "../assets/images/Discovery-list-image-removebg-preview.png";

export const numberWithCommas = (x) => {
  return x?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
};

export const EligibleOrganisationData = [
  {
    title: "ELIGIBLEORGANISATION",
    content: [
      " North Atlantic Treaty Organisation (NATO)",
      " Organisation for Economic Co-operation and Development (OECD)",
      " European Space Agency (ESA)",
      "Western European Union (WEU)",
      "Council of Europe",
    ],
  },
  {
    title: "U.N. MAIN ORGANISATION SPECIALIST AGENCIES AND INSTITUTIONS",
    content: [
      "  International Labour Organisation (ILO)",
      " United Nations Educational, Scientific and Cultural Organisation (UNESCO)",
      "International Telecommunications Union (ITU)",
      "International Bank for Reconstruction and Development (IBRD)",
      " International Development Association (IDA)",
      " International Monetary Fund (IMF)",
    ],
  },
  {
    title: "EUROPEAN COMMISSION",
    content: [
      "  European Parliament",
      "  European Court",
      " Their Executive bodies, institutions e.g. European Central Bank (ECB), European Patent Office (EPO) and agencies e.g. European Medicines Agency (EMA), Europol",
    ],
  },
  {
    title: "OTHER INTERNATIONAL ORGANISATIONS",
    content: [
      "  Interpol",
      " European Organisation for Nuclear Research (CERN)",
      " Organisation for Security and Co-operation in Europe (OSCE)",
    ],
  },
];

export const GlosaryData = [
  {
    title: "NOx",
    content: ["Nitrogen Oxide is a gas which affects air quality."],
  },
  {
    title: "CO2",
    content: ["Carbon Dioxide is a gas which affects global warming."],
  },
  {
    title: "PM",
    content: [
      "Particulate Matter: solid and liquid particles suspended in exhaust gases which affect air quality.",
    ],
  },
  {
    title: "EU5",
    content: [
      "Euro 5 standard from September 2009 – introduction of Particulate Filter.",
    ],
  },
  {
    title: "EU6",
    content: [
      "Euro 6 standard from September 2014 – introduction of Selective Catalytic Reduction (SCR).",
    ],
  },
  {
    title: "DEF",
    content: [
      "Diesel Exhaust Fluid (commonly known as AdBlueTM) - solution injected into exhaust to reduce NOx emissions.",
    ],
  },
  {
    title: "DPF",
    content: [
      "Diesel Particulate Filter removes 99.9% of all particles before they leave the exhaust.",
    ],
  },
  {
    title: "SCR",
    content: [
      "Selective Catalytic Reduction is an active exhaust gas after-treatment system reducing NOx.",
    ],
  },
  {
    title: "NEDC",
    content: [
      "New European Driving Cycle – is a laboratory testing method used for measuring emissions for vehicles and taxation policy.",
    ],
  },
  {
    title: "RDE",
    content: [
      "Real World Driving Emissions - Revised EU6 emissions legislation that is designed to simulate real world driving.",
    ],
  },
  {
    title: "WLTP",
    content: [
      "WLTP (Worldwide harmonised Light vehicle Test Procedure) is the new process that has been phased in from 2017, which measures fuel, energy consumption, range and emissions and replaces the previous NEDC (New European Driving Cycle) driving cycle to measure fuel consumption and emissions in passenger vehicles in Europe. This is designed to provide figures closer to real-world driving behaviour. It tests vehicles with optional equipment and with a more demanding test procedure and driving profile. Differences in figures between the two testing regimes are due to a change in how the vehicles are tested, rather than a reduction in performance.",
    ],
  },
  {
    title: "ULEZ",
    content: [
      "Ultra Low Emissions Zone – area in London where charges are imposed on pre-EU6 vehicles.",
    ],
  },
];

export const softwareUpdateNotes = [
  {
    heading: "19A3 – SOFTWARE RELEASE NOTES",
    title: "NEW FEATURES INCLUDED IN THIS RELEASE:",
    data: [
      {
        content: "WIRELESS SOFTWARE UPDATES",
        subContent: [
          "Vehicles will now have software updates activated as standard",
        ],
      },

      {
        content: "ISSUES FIXED IN THIS RELEASE:",
        subContent: [],
      },
      {
        content: "ANDROID AUTOTM",
        subContent: ["Improved automatic connection of Android AutoTM phones*"],
      },
      {
        content: "CLIMATE CONTROL",
        subContent: [
          "Fix for ‘Climate not available’ appearing on the instrument cluster and the climate controls not responding",
        ],
      },
      {
        content: "PARKING AID AND CAMERAS",
        subContent: [
          "If front camera is selected, it will no longer be replaced by the parking aid pop-up when approaching an obstacle",
          "Performance improvements for the reverse camera when rapidly switching from reverse to drive (not applicable to USA vehicles)",
          "After the front camera view has been automatically activated, you can now use the soft keys at the bottom of the screen to navigate away from this view",
        ],
      },
      {
        content: "(CHINESE LANGUAGE ONLY) BLUETOOTH®",
        subContent: [
          "Bluetooth® devices will now display their correct names in the source screen",
          " ‘Source’ in the Bluetooth® screen is now correctly translated",
        ],
      },
    ],
  },
  {
    heading: "19A4 – SOFTWARE RELEASE NOTES",
    title: "ISSUES FIXED IN THIS RELEASE:",
    data: [
      {
        content: "APPLE CARPLAY®",
        subContent: [
          "Call audio will no longer be lost on the system speaker after accepting a call using Apple CarPlay®*",
        ],
      },

      {
        content: "(E-PACE ONLY) DRIVE INFORMATION SCREEN",
        subContent: [
          "Front and rear torque distribution will now be displayed correctly",
          "Torque levels will now be displayed correctly",
        ],
      },
    ],
  },
  {
    heading: "19B – SOFTWARE RELEASE NOTES",
    title: "NEW FEATURES INCLUDED IN THIS RELEASE:",
    data: [
      {
        content: "SMARTPHONE PACK",
        subContent: [
          "The Smartphone Pack* comprising of Apple CarPlay®, Android AutoTM and Baidu Carlife (China only) is now enabled for compatible vehicles",
        ],
      },

      {
        content: "WI-FI",
        subContent: [
          "Vehicle systems that were previously unable to connect to Wi-Fi for wireless software updates can now do so. This will enable future wireless InControl Touch Pro software updates for these vehicles",
        ],
      },
      {
        content: "WIRELESS SOFTWARE UPDATES",
        subContent: [
          "The wireless software update customer experience has been simplified",
          "New software update pending notification icon",
          "Along with the Wi-Fi update, wireless software updates will be available for all InControl Touch Pro vehicles",
        ],
      },
      {
        content: "MEDIA PLAYER",
        subContent: [
          "On vehicle start-up, media sources will now be remembered from the last drive cycle",
          "When playing a podcast, +15 seconds and -15 seconds options will be displayed",
        ],
      },
      {
        content: "BLUETOOTH® PHONE",
        subContent: [
          "Improved experience when uploading recent call list from phone to vehicle",
        ],
      },
      {
        content: "(I-PACE ONLY) CLIMATE CONTROL",
        subContent: [
          "Climate functionality will now be fully supported when the vehicle’s power is on",
        ],
      },
      {
        content: "(ELECTRIC VEHICLES ONLY) NAVIGATION",
        subContent: [
          "Charging stations reachable with 1-4% battery charge on arrival will now be displayed",
          "Charging points are more accurately displayed on route based on vehicle state of charge",
        ],
      },
      {
        content: "ISSUES FIXED IN THIS RELEASE:",
        subContent: [],
      },
      {
        content: "GENERAL/SYSTEM",
        subContent: [
          "General performance improvements",
          "General stability improvements",
          "Fixed issue of no audio being available for a whole drive cycle seen in previous software version",
          "Side Panel theme will no longer change when the date format has been changed",
        ],
      },
      {
        content: "AUDIO QUALITY",
        subContent: ["General performance improvements"],
      },
      {
        content: "SMART PHONE PACK",
        subContent: [],
      },
      {
        content: "ANDROID Auto™",
        subContent: [
          "Improved Google Maps navigation audio when playing media using Android Auto™",
          " When a phone is connected by Android Auto™ it will no longer also show as a Bluetooth® source",
          "Android AutoTM icon will now always disappear after the phone has been disconnected",
          "Performance improvements for audio replies to Android Auto™",
          "General improved stability",
          "The Android phone header will now be displayed with the Android Auto™ icon",
        ],
      },
      {
        content: "APPLE CARPLAY®",
        subContent: [
          " Improved display of Apple CarPlay®",
          " General improved stability",
          " When playing media from Apple CarPlay®, it will be displayed in the media view",
          "Performance improvements for outgoing calls made using Apple CarPlay®",
        ],
      },
      {
        content: "BLUETOOTH® PHONE",
        subContent: [
          " Fixed an issue when using the steering wheel switch to move to the next song through Bluetooth audio® source",
          " When system is set to French, messages will no longer be read out in English",
          "  (Satellite Digital Audio Radio System only) Messages received when skipping through SDARS channels will no longer be lost",
        ],
      },
      {
        content: "(CHINA ONLY) BAIDU CARLIFE",
        subContent: [
          "Improved Baidu Carlife connectivity",
          " General improved stability",
        ],
      },
      {
        content: "AMBIENT LIGHTING",
        subContent: ["Fixed an issue with the ambient lighting control screen"],
      },
      {
        content: "BLUETOOTH®",
        subContent: [
          "(Chinese language only) Bluetooth® name will no longer change after switching media to Rear Seat Entertainment (RSE)",
          "Fixed an issue preventing Bluetooth® audio playing for a drive cycle",
        ],
      },
      {
        content: "CD PLAYER",
        subContent: [
          "Improved user experience when switching media source from Apple CarPlay® to CD",
        ],
      },
      {
        content: "CLIMATE CONTROL",
        subContent: [
          "Temperature settings and adjustments now available in the climate home screen",
          "Purify icon disabled when the vehicle is in defrost mode",
          "Third Row Climate Controls will only be available when the engine is running",
          "Temperature unit will no longer change from Celsius to Fahrenheit in Side Panel when language is changed to Portuguese",
          " Temperature control on rear screens will now work even when all zones are set to high",
          "(North American vehicles only) Fixed an issue of Smart Climate incorrectly showing the front passenger seat occupancy status",
        ],
      },
      {
        content: "CONNECTIVITY",
        subContent: [
          "Signal bars for vehicle connection strength will now display correctly",
          "When a phone is connected, Wi-Fi will now automatically enable after a power cycle",
          "Improved error message for data connection",
        ],
      },
      {
        content: "RADIO",
        subContent: [],
      },
      {
        content: "DAB RADIO",
        subContent: [
          "Improved DAB reliability",
          "(Head Up Display (HUD) vehicles only) Media information for DAB radio will now be displayed correctly in the HUD",
        ],
      },
      {
        content: "AM/FM RADIO",
        subContent: [
          "(InControl Touch Pro Duo only) Interactive Display Module 'A' (IDMA) will now show AM not FM when AM radio is playing",
        ],
      },
      {
        content: "SATELLITE RADIO",
        subContent: [
          "Satellite Digital Audio Radio System (SDARS) will now always launch first time",
          "SDARS screen will now load correctly ",
          "The correct song will now always play when selected from the satellite radio playlist",
          "General stability improvements",
        ],
      },
      {
        content: "DUAL VIEW SCREENS",
        subContent: [
          "Improved DVD playback image when a display theme is selected",
          "Display improvements for Bluetooth® settings icon ",
        ],
      },
      {
        content: "ECO DATA SCREEN",
        subContent: [
          "Improved accuracy for ECO data when climate control is in ECO mode",
        ],
      },
      {
        content: "FORWARD TRAFFIC DETECTION",
        subContent: [
          "Forward Traffic Detection alert will no longer stay visible if the camera view is changed during an alert",
        ],
      },
      {
        content: "PERSONALISATION",
        subContent: [
          "Keys can now be switched to different existing user profiles",
        ],
      },
      {
        content: "LIVE",
        subContent: [
          "Live will now launch from the 'Extra Features' screen",
          "Fixed an issue of Live sometimes not launching",
          " Live will no longer skip to the first page when opening a second one",
          "Display improvements for Live applications",
        ],
      },
      {
        content: "MEDIA PLAYER",
        subContent: [
          "General improved stability",
          "Improved handling of the track duration bar",
          " Tracks will no longer sometimes pause 3 seconds before the end",
          "Correct graphic will now be shown in the background of the Instrument Panel Cluster (IPC) media panel",
          "Fixed an issue of resuming podcast after ending a call",
        ],
      },
      {
        content: "NAVIGATION",
        subContent: [],
      },
      {
        content: "STANDARD NAVIGATION",
        subContent: [
          "Improved stability when resuming a route",
          " Improved accuracy of voice guidance",
          "Improved accuracy of safety cameras",
          "Display improvements for pop-up screens",
          'Improved "Send to Driver" from rear screens experience',
          "Improved map display during traffic side panel",
          " Improved map display within the Instrument Panel Cluster",
          "Improved reliability of address summary screen when the city selected is not unique",
          "Improved route calculation",
          "General improved reliability",
          "(Japan vehicles only) Rear screens will no longer display the Navigation icon",
          "Improved Traffic Info stability",
          "When the map license has been extended, the status will update immediately",
        ],
      },
      {
        content: "ELECTRIC VEHICLE NAVIGATION FEATURES",
        subContent: [
          "Navigation will no longer reset after switching the Instrument Panel Cluster to full map view",
          "Improved charge station searching",
        ],
      },
      {
        content: "PARKING AID",
        subContent: [
          "Fixed an issue with parking aid audio",
          "Fixed an issue with the parking aid 360 pop-up when in reverse",
        ],
      },
      {
        content: "REAR SEAT ENTERTAINMENT",
        subContent: [
          "Rear screens will no longer activate when opening the front console panel",
          "Time displayed on the rear screen will now match the front Touchscreen",
          '"SIRIUS XM" button is now functional in "All Settings" in Rear Seat Entertainment',
          "Improved performance of climate on the rear screen when using the remote control",
          " (Rear Massage seat vehicles only) Massage location now called ‘Upper back’ and ‘Lower back’ on the rear screens",
        ],
      },
      {
        content: "SEAT CONTROLS",
        subContent: [
          "Passenger seat icon now correctly displayed after favourite seat settings are selected",
          "Climate buttons will no longer be lost if the seat screen is opened multiple times in the same ignition cycle ",
          "Improved performance of seat massage buttons",
        ],
      },
      {
        content: "SPEECH",
        subContent: [
          "Improved graphics of speech tutorial",
          "Improved speech 'Call contact' reliability",
        ],
      },
      {
        content: "TELEVISION",
        subContent: [
          "Television reception will now recover automatically after leaving a low reception area",
          "Improved performance of 'No reception' notification",
          '(Brazil vehicles only) Touchscreen will no longer show a blank screen after Brazil Television runs for approximately 2.5 minutes, rear screen will no longer  show "No reception" message',
        ],
      },
      {
        content: "TERRAIN RESPONSE",
        subContent: [
          "Display improvements during terrain response mode",
          "Terrain response mode will be displayed in the correct language",
        ],
      },
      {
        content: "TEXT TO SPEECH",
        subContent: [
          "Text to speech will now behave correctly during playback",
        ],
      },
      {
        content: "TIMED CLIMATE",
        subContent: ["Timed climate will now use the same icon in all menus"],
      },
      {
        content: "TRANSLATIONS",
        subContent: ["Improved translations for all languages"],
      },
      {
        content: "VALET MODE",
        subContent: ["Improved phone reconnection when exiting valet mode "],
      },
      {
        content: "WADE SENSING",
        subContent: [
          "Improved graphics for wade sensing screen when on a hill ",
        ],
      },
      {
        content: "WEB BROWSER",
        subContent: [
          "A horizontal scroll bar is now displayed when a web page has been zoomed",
          "General speed and reliability improvements",
        ],
      },
    ],
  },
];

export const softwareUpdateFAQS = [
  {
    title: "GENERAL OVERVIEW",
    data: [
      {
        question: "What are wireless software updates?",
        answer:
          "Wireless software updates enable you to conveniently update your vehicle to the latest software at a time that suits you, without the need to visit a Retailer. They keep your vehicle at peak performance by maintaining key systems, such as your InControl Touch Pro.",
      },
      {
        question: "What are the benefits of wireless software updates?",
        answer:
          "Software updates provide the ability to further optimise vehicle systems, such as your InControl Touch Pro and vehicle connectivity systems. Receiving software updates on the go means that you will need less visits to your Retailer. The process is simple, easy to follow and can be done at a time that suits you.",
      },
      {
        question: "What software can be updated in my vehicle?",
        answer:
          "Both your vehicle’s InControl Touch Pro and the vehicle connectivity systems can receive software updates.",
      },
      {
        question: "How often will I receive software updates?",
        answer:
          "Software updates will be sent periodically to Land Rover vehicles, either when the need arises or when issues are identified with existing vehicle software.",
      },
      {
        question:
          "Is my vehicle eligible for an InControl Touch Pro software update?",
        answer:
          "To receive InControl Touch Pro wireless software updates your current software needs to be at 18B or above. You can check the software version of your InControl Touch Pro system through your Touchscreen by going to ‘Settings’ > ‘All Settings’ > ‘Software update’. The version of the InControl Touch Pro will be displayed as a long string of characters: e.g. ‘S18B_18.25.3-174880’.",
      },
    ],
  },
  {
    title: "HOW TO CONNECT YOUR VEHICLE",
    data: [
      {
        question:
          "How do I connect my vehicle to receive InControl Touch Pro updates?",
        answer:
          "The InControl Touch Pro system can be connected either through the SIM card inserted into your vehicle or through a Wi-Fi connection. You can also use a hotspot connection through your mobile device, but this may result in additional data charges.",
      },
      {
        question:
          "How do I connect my vehicle to receive connectivity system updates?",
        answer:
          "When you activate software updates in the settings menu the vehicle connectivity system will be connected automatically. Vehicle connectivity system updates are only available in Telematics enabled-countries.",
      },
      {
        question: "What happens if I lose connection during a download?",
        answer:
          "If connection is lost during a download, the download will pause until the connection is re-established.",
      },
      {
        question: "Are there data costs associated with a software update?",
        answer:
          "You may experience data costs if you connect through a SIM card inserted into your vehicle. In some countries, you will have received a SIM card on purchase of your vehicle. If used, you will not receive data charges for software updates.",
      },
    ],
  },
  {
    title: "HOW TO ACTIVATE SOFTWARE UPDATES",
    data: [
      {
        question: "How do I turn software updates on or off?",
        answer:
          "You can switch software updates on or off by going to ‘Settings’ > ‘All Settings’ > ‘Software update’ and pressing the power icon. When switching on software updates, you will need to accept the Terms & Conditions to activate the feature.",
      },
    ],
  },
  {
    title: "HOW TO PERFORM A SOFTWARE UPDATE",
    data: [
      {
        question: "How do I know if an update is available?",
        answer:
          "When an update is available, you will receive a pop-up notification on your Touchscreen. You can also manually check for available updates by going to ‘Settings’ > ‘All Settings’ > ‘Software update’ > ‘Check for update’.",
      },
      {
        question: "What do I need to do before installing an update?",
        answer:
          "To perform the update, you need to:Turn software updates on by going to ‘Settings’ > ‘All Settings’ > ‘Software update’ Accept the Terms & Conditions prior to download/installation Download the update to your vehicle Maintain cellular or Wi-Fi connectivity during the process Keep your vehicle locked and alarmed for the installation to begin (for vehicle connectivity system updates only) If updating an electric vehicle, unplug the charge cable",
      },
      {
        question: "How do I perform an InControl Touch Pro update?",
        answer:
          "You will receive a pop-up on the Touchscreen when an update is available for download/installation. Once accepted, the download and installation process will begin. The update will not interrupt your driving experience and you can continue to use your vehicle as normal. Upon completion, you will receive a successful installation notification on your Touchscreen.",
      },
      {
        question:
          "How do I perform an update to the vehicle connectivity system?",
        answer:
          "You will receive a pop-up on your Touchscreen when an update is available for download and, once downloaded, a second pop-up to install the update. Once you start the installation the process cannot be stopped. The installation may take up to 30 minutes. During this time you can access the vehicle but it cannot be driven until the update is complete.",
      },
      {
        question: "I have accepted the download; can I still use my vehicle?",
        answer:
          "Yes, while the system is downloading you can continue to use your vehicle as normal.",
      },
      {
        question: "How long does the software update installation take?",
        answer:
          "The installation may take up to 30 minutes. You will not be able to use your vehicle while installing vehicle connectivity and battery energy control system updates. You can still use your vehicle when installing an update to your InControl Touch Pro system.",
      },
      {
        question: "How do I know when the software update is complete?",
        answer:
          "A notification on your Touchscreen will inform you when the software has installed successfully.",
      },
      {
        question:
          "Do I need to have a signal for the installation to take place?",
        answer:
          "Yes, you need a mobile or Wi-Fi connection to download and complete the installation.",
      },
      {
        question: "Can I stop the installation once it has started?",
        answer: "No, once the installation has started it cannot be stopped.",
      },
    ],
  },
  {
    title: "ISSUE RESOLUTION",
    data: [
      {
        question: "The download has failed; what should I do?",
        answer:
          "You can use your vehicle as normal. A failed download will not affect your vehicle’s functionality in any way. Please contact the Land Rover Customer Relationship Centre or your local Land Rover Retailer and they will investigate further.",
      },
      {
        question: "What do I need to do before installing an update?",
        answer:
          "If your vehicle is experiencing issues when restarting following an update, please contact road-side assistance or your local Land Rover Retailer and they will investigate further.",
      },
      {
        question:
          "I skipped the update but I now want to install it. What should I do?",
        answer:
          "You can access the update through your vehicle’s Touchscreen by going to ‘Settings’ > ‘All Settings’ > ‘Software update’ > ‘Check for update’. If an update is available, a pop-up will appear giving you the option to download/install the update. If you prefer, you can visit your local Land Rover Retailer who will assist you with the update.",
      },
      {
        question: "Can I update the software while out of the country?",
        answer:
          "Yes. However, this may result in roaming charges depending on your in-vehicle data plan.",
      },
      {
        question: "Can I stop other people from updating my software?",
        answer:
          "Yes, you can add a PIN lock to your vehicle to protect your software update settings. To do this, go to ‘Settings’ > ‘All Settings’ > ‘Software update’ and select the lock icon.",
      },
      {
        question: "Do I need an InControl account for software updates?",
        answer:
          "No, you do not require an InControl account to perform software updates.",
      },
      {
        question:
          "Why haven’t I seen a notification for the InControl Touch Pro update?",
        answer:
          "InControl Touch Pro updates require a Wi-Fi connection or for a SIM card to be inserted within your vehicle. Check your personal SIM is installed and working correctly. Or alternatively, visit your local Land Rover Retailer who will assist you with the update.",
      },
    ],
  },
];

export const videoSwiperData = [
  {
    id: 1,
    image:
      "https://www.landrover.co.za//content/dam/lrdx/global/house-of-brands/range-rover/LR_DX_RangeRover_Flares_1920x1080_6___prores_190423_1.mp4.res/JLRHASH62FF50966BC35344993BA05B1EC310EBBBD0730C/LR_DX_RangeRover_Flares_1920x1080_6___prores_190423_1.mp4",
    title: "LEAD BY EXAMPLE",
    parag: "",
    button: "",
  },
  {
    id: 2,
    image:
      "https://www.landrover.co.za/content/dam/lrdx/global/l461/24my/int/L461SV24GL_303101570_DX_BANNER_H264_6_16x9_1.mp4.res/JLRHASHFF4A7FFF6EA331712C60CC09CA4EAD6FD511D7D0/L461SV24GL_303101570_DX_BANNER_H264_6_16x9_1.mp4",
    title: "THE NEW RANGE ROVER SPORT SV EDITIO ONE",
    parag: "",
    button: "EXPLORE THIS MODEL",
  },
];

export const defenderVideoSwiperData = [
  {
    id: 1,
    image:
      "https://www.landrover.co.za/content/dam/lrdx/global/house-of-brands/defender/LR_DX_Defender_Rocks_1920x1080_slow_6___prores_200423%20(1)_1.mp4",
    title: "EMBRACE THE IMPOSSIBLE",
    parag: "",
    button: "",
  },
];

export const rangeCardData = [
  {
    id: 1,
    image: cardImage,
    title: "RANGE ROVER",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Range Rover Hse ",
      "Hakuba silver",
      "Ebony perforated Semi-aniline ",
      "Automatic",
    ],
  },
  {
    id: 2,
    image: cardImage,
    title: "RANGE ROVER",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Range Rover Hse ",
      "Hakuba silver",
      "Leather seates with Perlino interior",
      "Automatic",
    ],
  },
  {
    id: 3,
    image: cardImage,
    title: "RANGE ROVER",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Range Rover Hse ",
      "Hakuba silver",
      "Ebony perforated Semi-aniline",
      "Automatic",
    ],
  },
  {
    id: 4,
    image: cardImage,
    title: "RANGE ROVER",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Range Rover Hse ",
      "Hakuba silver",
      "Leather seates with Perlino interior",
      "Automatic",
    ],
  },
  {
    id: 5,
    image: cardImage,
    title: "RANGE ROVER",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Range Rover Hse ",
      "Hakuba silver",
      "Ebony perforated Semi-aniline",
      "Automatic",
    ],
  },
  {
    id: 6,
    image: cardImage,
    title: "RANGE ROVER",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Range Rover Hse ",
      "Hakuba silver",
      "Ebony perforated Semi-aniline",
      "Automatic",
    ],
  },
  {
    id: 7,
    image: cardImage,
    title: "RANGE ROVER",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Range Rover Hse ",
      "Hakuba silver",
      "Ebony perforated Semi-aniline",
      "Automatic",
    ],
  },
];

export const defenderCardData = [
  {
    id: 1,
    image: defenderImage,
    title: "Defender",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Defender Hse ",
      "Hakuba silver",
      "Leather seates with Perlino interior",
      "Automatic",
    ],
  },
  {
    id: 2,
    image: defenderImage1,
    title: "Defender",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Defender Hse ",
      "Hakuba silver",
      "Ebony perforated Semi-aniline",
      "Automatic",
    ],
  },
  {
    id: 3,
    image: defenderImage,
    title: "Defender",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Defender Hse ",
      "Hakuba silver",
      "Leather seates with Perlino interior",
      "Automatic",
    ],
  },
  {
    id: 4,
    image: defenderImage1,
    title: "Defender",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Defender Hse ",
      "Hakuba silver",
      "Ebony perforated Semi-aniline",
      "Automatic",
    ],
  },
  {
    id: 5,
    image: defenderImage,
    title: "Defender",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Defender Hse ",
      "Hakuba silver",
      "Leather seates with Perlino interior",
      "Automatic",
    ],
  },
];

export const discoveryCardData = [
  {
    id: 1,
    image: discoveryImage,
    title: "Discovery",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Discovery Hse ",
      "Hakuba silver",
      "Ebony perforated Semi-aniline",
      "Automatic",
    ],
  },
  {
    id: 2,
    image: discoveryImage,
    title: "Discovery",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Discovery Hse ",
      "Hakuba silver",
      "Leather seates with Perlino interior",
      "Automatic",
    ],
  },
  {
    id: 3,
    image: discoveryImage,
    title: "Discovery",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Discovery Hse ",
      "Hakuba silver",
      "Ebony perforated Semi-aniline",
      "Automatic",
    ],
  },
  {
    id: 4,
    image: discoveryImage,
    title: "Discovery",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Discovery Hse ",
      "Hakuba silver",
      "Leather seates with Perlino interior",
      "Automatic",
    ],
  },
  {
    id: 5,
    image: discoveryImage,
    title: "Discovery",
    staus: "AVAILABLE NOW",
    fuel: "DIESEL D350 AWD AUTOMATIC",
    price: "49846,51",
    priceFirst: "3274000,00",
    feature: [
      "Standard Wheelbase",
      "Discovery Hse ",
      "Hakuba silver",
      "Ebony perforated Semi-aniline",
      "Automatic",
    ],
  },
];

export const rangeRoverFilterData = [
  {
    vehicles: [
      "Range Rover Sports ",
      "Range Rover",
      "Range Rover",
      "Range Rover",
      "Range Rover",
      "Range Rover",
      "Range Rover",
      "Range Rover",
    ],
    engine: ["Range Rover", "Range Rover", "Range Rover", "Range Rover"],
    trim: ["Range Rover", "Range Rover", "Range Rover", "Range Rover"],
    model_year: ["Range Rover", "Range Rover"],
  },
];

export const defenderFilterData = [
  {
    vehicles: [
      "Defender 90",
      "Defender 110",
      "Defender 100",
      "Defender 80",
      "Defender 70",
      "Defender 60",
      "Defender 10",
    ],
    engine: ["Defender 110", "Defender 110", "Defender 110", "Defender 110"],
    trim: ["Defender 110", "Defender 110", "Defender 110", "Defender 110"],
    model_year: ["Defender 90", "Defender 90"],
  },
];

export const discoveryFilterData = [
  {
    vehicles: [
      "Discovery",
      "Discovery",
      "Discovery",
      "Discovery",
      "Discovery",
      "Discovery",
      "Discovery",
    ],
    engine: ["Discovery", "Discovery", "Discovery", "Discovery"],
    trim: ["Discovery", "Discovery", "Discovery", "Discovery"],
    model_year: ["Discovery", "Discovery", "Discovery"],
  },
];

export const detailsPageSwiper = [
  {
    id: 1,
    image: image4,
    title: "RANGE ROVER",
    fuel: "HSB",
    price: "AED $2250",
  },
  {
    id: 2,
    image: image4,
    title: "RANGE ROVER",
    fuel: "HSB",
    price: "AED $2250",
  },
  {
    id: 3,
    image: image4,
    title: "RANGE ROVER",
    fuel: "HSB",
    price: "AED $2250",
  },
  {
    id: 4,
    image: image4,
    title: "RANGE ROVER",
    fuel: "HSB",
    price: "AED $2250",
  },
];

export const occupationTypes = [
  {
    CODE: "CR00",
    DESCRIPTION: "CREATIVE",
    XOCG_CODE: null,
    CIS_CODE: "CR",
  },
  { CODE: "CR01", DESCRIPTION: "ACTOR", XOCG_CODE: "CR00", CIS_CODE: "CR" },
  {
    CODE: "CR02",
    DESCRIPTION: "ACTRESS",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  { CODE: "CR03", DESCRIPTION: "ANNOUNCER", XOCG_CODE: "CR00", CIS_CODE: "CR" },
  {
    CODE: "CR04",
    DESCRIPTION: "ART DIRECTOR",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  { CODE: "CR05", DESCRIPTION: "ARTIST", XOCG_CODE: "CR00", CIS_CODE: "CR" },
  {
    CODE: "CR06",
    DESCRIPTION: "AUTHOR",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR07",
    DESCRIPTION: "BALLET DANCER",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR08",
    DESCRIPTION: "BANDLEADER",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR09",
    DESCRIPTION: "BIOGRAPHER",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR10",
    DESCRIPTION: "BROADCASTER",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR11",
    DESCRIPTION: "CARTOONIST",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR12",
    DESCRIPTION: "COMEDIAN(IENNE)",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR13",
    DESCRIPTION: "COMMENTATOR",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR14",
    DESCRIPTION: "COMPERE",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR15",
    DESCRIPTION: "COPYWRITER",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR16",
    DESCRIPTION: "CREATIVE ARTIST",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR17",
    DESCRIPTION: "DANCE INSTRUCTOR",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR18",
    DESCRIPTION: "DANCER",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  { CODE: "CR19", DESCRIPTION: "DESIGNER", XOCG_CODE: "CR00", CIS_CODE: "CR" },
  {
    CODE: "CR20",
    DESCRIPTION: "DRAMATIST",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR21",
    DESCRIPTION: "ENTERTAINER",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR22",
    DESCRIPTION: "FASHION AGENT",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR23",
    DESCRIPTION: "FASHION MODEL",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR24",
    DESCRIPTION: "GHOST WRITERS",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR25",
    DESCRIPTION: "ILLUSTRATOR",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR26",
    DESCRIPTION: "INTERIOR DECORATOR",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR28",
    DESCRIPTION: "JOURNALIST",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR29",
    DESCRIPTION: "LANDSCAPER",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  { CODE: "CR30", DESCRIPTION: "MODEL", XOCG_CODE: "CR00", CIS_CODE: "CR" },
  {
    CODE: "CR31",
    DESCRIPTION: "MUSICIAN",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR32",
    DESCRIPTION: "NEWSCASTER",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR33",
    DESCRIPTION: "NIGHT CLUB ENTERTAINER",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  { CODE: "CR34", DESCRIPTION: "NOVELIST", XOCG_CODE: "CR00", CIS_CODE: "CR" },
  {
    CODE: "CR35",
    DESCRIPTION: "PHOTOGRAPHER",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR36",
    DESCRIPTION: "PUBLIC RELATIONS PERSON",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  { CODE: "CR37", DESCRIPTION: "READER", XOCG_CODE: "CR00", CIS_CODE: "CR" },
  {
    CODE: "CR38",
    DESCRIPTION: "REPORTER",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  { CODE: "CR39", DESCRIPTION: "SCULPTOR", XOCG_CODE: "CR00", CIS_CODE: "CR" },
  {
    CODE: "CR40",
    DESCRIPTION: "SINGER(SEMI- OR PROFESSIONAL)",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR41",
    DESCRIPTION: "SPORTS FIGURE",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR42",
    DESCRIPTION: "SPORTS INSTRUCTOR (PROFESSIONAL)",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR43",
    DESCRIPTION: "SPORTSMAN/WOMAN (PROFESSIONAL)",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR44",
    DESCRIPTION: "THEATRICAL AGENT",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  {
    CODE: "CR45",
    DESCRIPTION: "WINDOW DRESSER",
    XOCG_CODE: "CR00",
    CIS_CODE: "CR",
  },
  { CODE: "CR46", DESCRIPTION: "WRITER", XOCG_CODE: "CR00", CIS_CODE: "CR" },
  {
    CODE: "DR01",
    DESCRIPTION: "DRIVER",
    XOCG_CODE: "DR01",
    CIS_CODE: "DR",
  },
  {
    CODE: "EL00",
    DESCRIPTION: "ESTATE LATE OCC CODE",
    XOCG_CODE: "EL00",
    CIS_CODE: "EL",
  },
  { CODE: "EX00", DESCRIPTION: "EXECUTIVE", XOCG_CODE: null, CIS_CODE: "EX" },
  {
    CODE: "EX01",
    DESCRIPTION: "AIRLINE PILOT",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX02",
    DESCRIPTION: "ASSISTANT GENERAL MANAGER",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX03",
    DESCRIPTION: "ASSISTANT RESIDENT ENGINEER",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX04",
    DESCRIPTION: "ASSISTANT SUPERINTENDENT",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX05",
    DESCRIPTION: "CAPTAIN (AIRWAYS)",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX06",
    DESCRIPTION: "CAPTAIN (MERCHANT NAVY)",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX07",
    DESCRIPTION: "CHAIRMAN OF THE BOARD",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX08",
    DESCRIPTION: "COMPANY SECRETARY",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX09",
    DESCRIPTION: "COMPANY TREASURER",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX10",
    DESCRIPTION: "DEPUTY MANAGING DIRECTOR",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  { CODE: "EX11", DESCRIPTION: "DIRECTOR", XOCG_CODE: "EX00", CIS_CODE: "EX" },
  {
    CODE: "EX12",
    DESCRIPTION: "EDITOR",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  { CODE: "EX13", DESCRIPTION: "EXECUTIVE", XOCG_CODE: "EX00", CIS_CODE: "EX" },
  {
    CODE: "EX14",
    DESCRIPTION: "GENERAL MANAGER",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX15",
    DESCRIPTION: "GROUP MANAGER",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX16",
    DESCRIPTION: "HARBOUR-MASTER",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX17",
    DESCRIPTION: "MANAGING DIRECTOR",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX18",
    DESCRIPTION: "MINE MANAGER",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX19",
    DESCRIPTION: "PRESIDENT (COMPANY)",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX20",
    DESCRIPTION: "PRINCIPAL OF A COLLEGE",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX21",
    DESCRIPTION: "REGIONAL MANAGER",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX22",
    DESCRIPTION: "RESIDENT ENGINEER",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX23",
    DESCRIPTION: "SENIOR SUPERINTENDENTS",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX24",
    DESCRIPTION: "SUPERINTENDENTS (TRANSNET/HOSP)",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX25",
    DESCRIPTION: "TOWN CLERK",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX26",
    DESCRIPTION: "TREASURER (COMPANY)",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX27",
    DESCRIPTION: "UNIVERSITY PRINCIPAL",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX28",
    DESCRIPTION: "VICE PRESIDENT",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "EX29",
    DESCRIPTION: "TAXI OWNER",
    XOCG_CODE: "EX00",
    CIS_CODE: "EX",
  },
  {
    CODE: "F000",
    DESCRIPTION: "FARMER - PIGS",
    XOCG_CODE: "FA00",
    CIS_CODE: "F0",
  },
  {
    CODE: "F100",
    DESCRIPTION: "FARMER - OSTRICHES",
    XOCG_CODE: "FA00",
    CIS_CODE: "F1",
  },
  {
    CODE: "F200",
    DESCRIPTION: "FARMER - POULTRY",
    XOCG_CODE: "FA00",
    CIS_CODE: "F2",
  },
  {
    CODE: "F300",
    DESCRIPTION: "FARMER - OTHER LIVESTOCK",
    XOCG_CODE: "FA00",
    CIS_CODE: "F3",
  },
  {
    CODE: "F400",
    DESCRIPTION: "FARMER - FISH HATCHERIES/FISH FARMS",
    XOCG_CODE: "FA00",
    CIS_CODE: "F4",
  },
  {
    CODE: "FA00",
    DESCRIPTION: "FARMER - UNSPECIFIED",
    XOCG_CODE: "FA00",
    CIS_CODE: "FA",
  },
  {
    CODE: "FB00",
    DESCRIPTION: "FARMER - MAIZE",
    XOCG_CODE: "FA00",
    CIS_CODE: "FB",
  },
  {
    CODE: "FC00",
    DESCRIPTION: "FARMER - OTHER SUMMER GRAIN",
    XOCG_CODE: "FA00",
    CIS_CODE: "FC",
  },
  {
    CODE: "FD00",
    DESCRIPTION: "FARMER - WHEAT",
    XOCG_CODE: "FA00",
    CIS_CODE: "FD",
  },
  {
    CODE: "FE00",
    DESCRIPTION: "FARMER - OTHER WINTER GRAIN",
    XOCG_CODE: "FA00",
    CIS_CODE: "FE",
  },
  {
    CODE: "FF00",
    DESCRIPTION: "FARMER - OIL SEEDS AND DRY BEANS",
    XOCG_CODE: "FA00",
    CIS_CODE: "FF",
  },
  {
    CODE: "FG00",
    DESCRIPTION: "FARMER - COTTON",
    XOCG_CODE: "FA00",
    CIS_CODE: "FG",
  },
  {
    CODE: "FH00",
    DESCRIPTION: "FARMER - TOBACCO",
    XOCG_CODE: "FA00",
    CIS_CODE: "FH",
  },
  {
    CODE: "FI00",
    DESCRIPTION: "FARMER - SUGAR CANE",
    XOCG_CODE: "FA00",
    CIS_CODE: "FI",
  },
  {
    CODE: "FJ00",
    DESCRIPTION: "FARMER - LIVE STOCK FEED",
    XOCG_CODE: "FA00",
    CIS_CODE: "FJ",
  },
  {
    CODE: "FK00",
    DESCRIPTION: "FARMER - OTHER YEARLY ENTERPRISES",
    XOCG_CODE: "FA00",
    CIS_CODE: "FK",
  },
  {
    CODE: "FL00",
    DESCRIPTION: "FARMER - VEGETABLES",
    XOCG_CODE: "FA00",
    CIS_CODE: "FL",
  },
  {
    CODE: "FM00",
    DESCRIPTION: "FARMER - FLOWERS",
    XOCG_CODE: "FA00",
    CIS_CODE: "FM",
  },
  {
    CODE: "FN00",
    DESCRIPTION: "FARMER - CITRIS FRUIT",
    XOCG_CODE: "FA00",
    CIS_CODE: "FN",
  },
  {
    CODE: "FO00",
    DESCRIPTION: "FARMER - GRAPES",
    XOCG_CODE: "FA00",
    CIS_CODE: "FO",
  },
  {
    CODE: "FP00",
    DESCRIPTION: "FARMER - NUTS",
    XOCG_CODE: "FA00",
    CIS_CODE: "FP",
  },
  {
    CODE: "FQ00",
    DESCRIPTION: "FARMER - SUB TROPICAL FRUIT",
    XOCG_CODE: "FA00",
    CIS_CODE: "FQ",
  },
  {
    CODE: "FR00",
    DESCRIPTION: "FARMER - DECIDUOUS FRUIT",
    XOCG_CODE: "FA00",
    CIS_CODE: "FR",
  },
  {
    CODE: "FS00",
    DESCRIPTION: "FARMER - TEA/COFFEE",
    XOCG_CODE: "FA00",
    CIS_CODE: "FS",
  },
  {
    CODE: "FT00",
    DESCRIPTION: "FARMER - PLANTATIONS",
    XOCG_CODE: "FA00",
    CIS_CODE: "FT",
  },
  {
    CODE: "FU00",
    DESCRIPTION: "FARMER - OTHER AGRICULTURE",
    XOCG_CODE: "FA00",
    CIS_CODE: "FU",
  },
  {
    CODE: "FV00",
    DESCRIPTION: "FARMER - BEEF",
    XOCG_CODE: "FA00",
    CIS_CODE: "FV",
  },
  {
    CODE: "FW00",
    DESCRIPTION: "FARMER - DAIRY",
    XOCG_CODE: "FA00",
    CIS_CODE: "FW",
  },
  {
    CODE: "FX00",
    DESCRIPTION: "FARMER - SHEEP",
    XOCG_CODE: "FA00",
    CIS_CODE: "FX",
  },
  {
    CODE: "FY00",
    DESCRIPTION: "FARMER - HORSES",
    XOCG_CODE: "FA00",
    CIS_CODE: "FY",
  },
  {
    CODE: "FZ00",
    DESCRIPTION: "FARMER - GOATS",
    XOCG_CODE: "FA00",
    CIS_CODE: "FZ",
  },
  {
    CODE: "GU00",
    DESCRIPTION: "GUARDS",
    XOCG_CODE: null,
    CIS_CODE: "GU",
  },
  {
    CODE: "GU01",
    DESCRIPTION: "CHILD CARE OFFICER",
    XOCG_CODE: "GU00",
    CIS_CODE: "GU",
  },
  {
    CODE: "GU02",
    DESCRIPTION: "COMMISSIONAIRE",
    XOCG_CODE: "GU00",
    CIS_CODE: "GU",
  },
  { CODE: "GU03", DESCRIPTION: "CONSTABLE", XOCG_CODE: "GU00", CIS_CODE: "GU" },
  {
    CODE: "GU04",
    DESCRIPTION: "CONTROLLER",
    XOCG_CODE: "GU00",
    CIS_CODE: "GU",
  },
  { CODE: "GU05", DESCRIPTION: "FIREMAN", XOCG_CODE: "GU00", CIS_CODE: "GU" },
  {
    CODE: "GU06",
    DESCRIPTION: "INVESTIGATOR",
    XOCG_CODE: "GU00",
    CIS_CODE: "GU",
  },
  { CODE: "GU07", DESCRIPTION: "LIFEGUARD", XOCG_CODE: "GU00", CIS_CODE: "GU" },
  {
    CODE: "GU08",
    DESCRIPTION: "POSTMAN",
    XOCG_CODE: "GU00",
    CIS_CODE: "GU",
  },
  {
    CODE: "GU09",
    DESCRIPTION: "PRISON OFFICER",
    XOCG_CODE: "GU00",
    CIS_CODE: "GU",
  },
  {
    CODE: "GU10",
    DESCRIPTION: "PROBATION OFFICER",
    XOCG_CODE: "GU00",
    CIS_CODE: "GU",
  },
  {
    CODE: "GU11",
    DESCRIPTION: "SECURITY GUARD",
    XOCG_CODE: "GU00",
    CIS_CODE: "GU",
  },
  {
    CODE: "GU12",
    DESCRIPTION: "SECURITY OFFICER",
    XOCG_CODE: "GU00",
    CIS_CODE: "GU",
  },
  {
    CODE: "GU13",
    DESCRIPTION: "SOCIAL WORKER",
    XOCG_CODE: "GU00",
    CIS_CODE: "GU",
  },
  {
    CODE: "GU14",
    DESCRIPTION: "TRAFFIC WARDEN",
    XOCG_CODE: "GU00",
    CIS_CODE: "GU",
  },
  {
    CODE: "GU15",
    DESCRIPTION: "TRAFFIC CONTROLLER",
    XOCG_CODE: "GU00",
    CIS_CODE: "GU",
  },
  {
    CODE: "GU16",
    DESCRIPTION: "WORKS POLICEMAN",
    XOCG_CODE: "GU00",
    CIS_CODE: "GU",
  },
  { CODE: "HW01", DESCRIPTION: "HOUSEWIFE", XOCG_CODE: "HW01", CIS_CODE: "HW" },
  {
    CODE: "LA00",
    DESCRIPTION: "LABOURERS",
    XOCG_CODE: null,
    CIS_CODE: "LA",
  },
  {
    CODE: "LA01",
    DESCRIPTION: "AGRICULTURAL WORKER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  { CODE: "LA02", DESCRIPTION: "ATTENDANT", XOCG_CODE: "LA00", CIS_CODE: "LA" },
  {
    CODE: "LA03",
    DESCRIPTION: "BOILER MAN",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA04",
    DESCRIPTION: "BUILDING MAINTENANCE",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  { CODE: "LA05", DESCRIPTION: "CARETAKER", XOCG_CODE: "LA00", CIS_CODE: "LA" },
  {
    CODE: "LA06",
    DESCRIPTION: "CAR PARK ATTENDANT",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  { CODE: "LA07", DESCRIPTION: "CLEANER", XOCG_CODE: "LA00", CIS_CODE: "LA" },
  {
    CODE: "LA08",
    DESCRIPTION: "COALMAN",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA09",
    DESCRIPTION: "COUNCIL WORKER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA10",
    DESCRIPTION: "DISH WASHER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  { CODE: "LA11", DESCRIPTION: "DITCHER", XOCG_CODE: "LA00", CIS_CODE: "LA" },
  {
    CODE: "LA12",
    DESCRIPTION: "DOCKER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA13",
    DESCRIPTION: "DOMESTIC HELPER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA14",
    DESCRIPTION: "DUSTBINMAN",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA15",
    DESCRIPTION: "FARM LABOURER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA16",
    DESCRIPTION: "FEEDER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA17",
    DESCRIPTION: "FISH FILLETER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA18",
    DESCRIPTION: "FLOOR POLISHER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  { CODE: "LA19", DESCRIPTION: "FORECOURT", XOCG_CODE: "LA00", CIS_CODE: "LA" },
  {
    CODE: "LA20",
    DESCRIPTION: "GARDENER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA21",
    DESCRIPTION: "GENERAL WORKER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA22",
    DESCRIPTION: "GETTER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA23",
    DESCRIPTION: "GRAVEDIGGER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA24",
    DESCRIPTION: "GROUNDSMAN",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  { CODE: "LA25", DESCRIPTION: "HELPER", XOCG_CODE: "LA00", CIS_CODE: "LA" },
  {
    CODE: "LA26",
    DESCRIPTION: "HANDYMAN",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  { CODE: "LA27", DESCRIPTION: "JANITOR", XOCG_CODE: "LA00", CIS_CODE: "LA" },
  {
    CODE: "LA28",
    DESCRIPTION: "LABOURER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA29",
    DESCRIPTION: "LORRY DRIVER'S MATE",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA30",
    DESCRIPTION: "LUGGAGEMAN",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA31",
    DESCRIPTION: "MAID",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA32",
    DESCRIPTION: "MANUAL WORKER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA33",
    DESCRIPTION: "MILKMAN",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA34",
    DESCRIPTION: "ODD JOBS MAN",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA35",
    DESCRIPTION: "OFFICE CLEARNER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA36",
    DESCRIPTION: "PARKING LOT ATTENDANT",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA37",
    DESCRIPTION: "PETROL STATION ATTENDANT",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA38",
    DESCRIPTION: "POOL ATTENDANT",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA39",
    DESCRIPTION: "QUARRY WORKER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA40",
    DESCRIPTION: "REFUSE COLLECTOR",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA41",
    DESCRIPTION: "ROADSWEEPER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  { CODE: "LA42", DESCRIPTION: "SERVANT", XOCG_CODE: "LA00", CIS_CODE: "LA" },
  {
    CODE: "LA43",
    DESCRIPTION: "STAGEMAN",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  { CODE: "LA44", DESCRIPTION: "STEVEDORE", XOCG_CODE: "LA00", CIS_CODE: "LA" },
  {
    CODE: "LA45",
    DESCRIPTION: "STOCKMAN",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA46",
    DESCRIPTION: "STOCK REPLENISHER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA47",
    DESCRIPTION: "TRACTOR DRIVER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA48",
    DESCRIPTION: "WAREHOUSEMAN",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  {
    CODE: "LA49",
    DESCRIPTION: "WINDOWCLEANER",
    XOCG_CODE: "LA00",
    CIS_CODE: "LA",
  },
  { CODE: "MA00", DESCRIPTION: "MANAGER", XOCG_CODE: null, CIS_CODE: "MA" },
  {
    CODE: "MA01",
    DESCRIPTION: "ADMINISTRATIVE ASSISTANT",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA02",
    DESCRIPTION: "ADMINISTRATIVE MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA03",
    DESCRIPTION: "BANKSMAN (COAL MINE OVERLOOKER)",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA04",
    DESCRIPTION: "CHARGE HAND",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA05",
    DESCRIPTION: "COMPOUND MANAGER (MINING)",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA06",
    DESCRIPTION: "CREDIT CONTROLLER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA07",
    DESCRIPTION: "DEPARTMENT HEAD",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA08",
    DESCRIPTION: "DEPARTMENT MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA09",
    DESCRIPTION: "DISTRICT MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA10",
    DESCRIPTION: "ESTATE MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA11",
    DESCRIPTION: "EXPORT MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA12",
    DESCRIPTION: "FARM MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA13",
    DESCRIPTION: "FLOOR WALKER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  { CODE: "MA14", DESCRIPTION: "FOREMAN", XOCG_CODE: "MA00", CIS_CODE: "MA" },
  {
    CODE: "MA15",
    DESCRIPTION: "FUNERAL DIRECTOR",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA16",
    DESCRIPTION: "GOVERNMENT DEPARTMENT ADMINISTRATOR",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA17",
    DESCRIPTION: "HEADMASTER (GOVT SCHOOL)",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  { CODE: "MA18", DESCRIPTION: "HOTELIER", XOCG_CODE: "MA00", CIS_CODE: "MA" },
  {
    CODE: "MA19",
    DESCRIPTION: "JEWELLER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA20",
    DESCRIPTION: "LABOUR MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA21",
    DESCRIPTION: "MANAGER (LINE MANAGER)",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA22",
    DESCRIPTION: "MANPOWER MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA23",
    DESCRIPTION: "MINE CAPTAIN",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  { CODE: "MA24", DESCRIPTION: "MATRON", XOCG_CODE: "MA00", CIS_CODE: "MA" },
  {
    CODE: "MA25",
    DESCRIPTION: "NAVIGATING OFFICER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA26",
    DESCRIPTION: "OVERLOOKER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA27",
    DESCRIPTION: "OVERSEER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA28",
    DESCRIPTION: "PERSONNEL MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA29",
    DESCRIPTION: "POSTMASTER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA30",
    DESCRIPTION: "PRODUCTIVITY MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  { CODE: "MA31", DESCRIPTION: "PUBLICAN", XOCG_CODE: "MA00", CIS_CODE: "MA" },
  {
    CODE: "MA32",
    DESCRIPTION: "PUBLISHER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA33",
    DESCRIPTION: "REGISTRAR (UNIVERSITY)",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA34",
    DESCRIPTION: "SALES MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA35",
    DESCRIPTION: "SECTION ENGINEER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA36",
    DESCRIPTION: "SECTION MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA37",
    DESCRIPTION: "SENIOR OFFICER (MERCHANT NAVY)",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA38",
    DESCRIPTION: "SHIFT BOSS",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA39",
    DESCRIPTION: "SHOP FOREMAN",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA40",
    DESCRIPTION: "STAGE MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA41",
    DESCRIPTION: "SUPERVISOR",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA42",
    DESCRIPTION: "SYSTEMS MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA43",
    DESCRIPTION: "TRADE UNION OFFICIAL",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA44",
    DESCRIPTION: "TRAINING MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "MA45",
    DESCRIPTION: "ASSISTANT MANAGER",
    XOCG_CODE: "MA00",
    CIS_CODE: "MA",
  },
  {
    CODE: "ME00",
    DESCRIPTION: "MILITARY / POLICE - ENLISTED OR RANK UNKNOWN",
    XOCG_CODE: null,
    CIS_CODE: "ME",
  },
  {
    CODE: "ME01",
    DESCRIPTION: "NAVY - ORDINARY SEAMAN",
    XOCG_CODE: "ME00",
    CIS_CODE: "ME",
  },
  {
    CODE: "ME02",
    DESCRIPTION: "NAVY - ABLE SEAMAN",
    XOCG_CODE: "ME00",
    CIS_CODE: "ME",
  },
  {
    CODE: "ME03",
    DESCRIPTION: "NAVY - LEADING SEAMAN",
    XOCG_CODE: "ME00",
    CIS_CODE: "ME",
  },
  {
    CODE: "ME04",
    DESCRIPTION: "ARMY - PRIVATE",
    XOCG_CODE: "ME00",
    CIS_CODE: "ME",
  },
  {
    CODE: "ME05",
    DESCRIPTION: "ARMY - LANCE CORPORAL",
    XOCG_CODE: "ME00",
    CIS_CODE: "ME",
  },
  {
    CODE: "ME06",
    DESCRIPTION: "ARMY - CORPORAL",
    XOCG_CODE: "ME00",
    CIS_CODE: "ME",
  },
  {
    CODE: "ME07",
    DESCRIPTION: "AIRFORCE - PRIVATE",
    XOCG_CODE: "ME00",
    CIS_CODE: "ME",
  },
  {
    CODE: "ME08",
    DESCRIPTION: "AIRFORCE - LANCE CORPORAL CONSTABLE",
    XOCG_CODE: "ME00",
    CIS_CODE: "ME",
  },
  {
    CODE: "ME09",
    DESCRIPTION: "AIRFORCE - CORPORAL",
    XOCG_CODE: "ME00",
    CIS_CODE: "ME",
  },
  {
    CODE: "ME10",
    DESCRIPTION: "POLICE - STUDENT",
    XOCG_CODE: "ME00",
    CIS_CODE: "ME",
  },
  {
    CODE: "ME11",
    DESCRIPTION: "POLICE - SERGEANT",
    XOCG_CODE: "ME00",
    CIS_CODE: "ME",
  },
  { CODE: "MI01", DESCRIPTION: "MINOR", XOCG_CODE: null, CIS_CODE: "MI" },
  {
    CODE: "MN00",
    DESCRIPTION: "MILITARY / POLICE - NON COMMISSIONED OFFICER",
    XOCG_CODE: null,
    CIS_CODE: "MN",
  },
  {
    CODE: "MN01",
    DESCRIPTION: "NAVY - PETTY OFFICER",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN02",
    DESCRIPTION: "NAVY - SERGEANT",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN03",
    DESCRIPTION: "NAVY - CHIEF PETTY OFFIC",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN04",
    DESCRIPTION: "NAVY - SERGEANT MAJOR",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN05",
    DESCRIPTION: "NAVY - WARRANT OFFICER1",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN06",
    DESCRIPTION: "NAVY - WARRANT OFFICER2",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN07",
    DESCRIPTION: "NAVY - MIDSHIPMAN",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN08",
    DESCRIPTION: "NAVY - ENSIGN",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN09",
    DESCRIPTION: "ARMY - SERGEANT",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN10",
    DESCRIPTION: "ARMY - STAFF SERGEANT",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN11",
    DESCRIPTION: "ARMY - SERGEANT MAJOR",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN12",
    DESCRIPTION: "ARMY - WARRANT OFF. 1",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN13",
    DESCRIPTION: "ARMY - WARRANT OFF. 2",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN14",
    DESCRIPTION: "ARMY - CANDIDATE OFFICER",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN15",
    DESCRIPTION: "ARMY - SECOND LIEUTENANT",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN16",
    DESCRIPTION: "AIRFORCE - SERGEANT",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN17",
    DESCRIPTION: "AIRFORCE - FLIGHT SERGEANT",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN18",
    DESCRIPTION: "AIRFORCE - WARRANT OFF. 1",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN19",
    DESCRIPTION: "AIRFORCE - WARRANT OFF. 2",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN20",
    DESCRIPTION: "AIRFORCE - CANDIDATE OFF.",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN21",
    DESCRIPTION: "AIRFORCE - SECOND LIEUTENANT",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MN22",
    DESCRIPTION: "POLICE - INSPECTOR",
    XOCG_CODE: "MN00",
    CIS_CODE: "MN",
  },
  {
    CODE: "MO00",
    DESCRIPTION: "MILITARY / POLICE OFFICER",
    XOCG_CODE: null,
    CIS_CODE: "MO",
  },
  {
    CODE: "MO01",
    DESCRIPTION: "NAVY - SUB LIEUTENANT",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO02",
    DESCRIPTION: "NAVY - LIEUTENANT",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO03",
    DESCRIPTION: "NAVY - LT. COMMANDER",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO04",
    DESCRIPTION: "NAVY - COMMANDER",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO05",
    DESCRIPTION: "NAVY - CAPTAIN",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO06",
    DESCRIPTION: "NAVY - COMMODORE",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO07",
    DESCRIPTION: "NAVY - VICE ADMIRAL",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO08",
    DESCRIPTION: "NAVY - REAR ADMIRAL",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO09",
    DESCRIPTION: "NAVY - ADMIRAL",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO10",
    DESCRIPTION: "ARMY - FIRST LIEUTENANT",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO11",
    DESCRIPTION: "ARMY - CAPTAIN",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO12",
    DESCRIPTION: "ARMY - MAJOR",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO13",
    DESCRIPTION: "ARMY - COMMANDANT",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO14",
    DESCRIPTION: "ARMY - COLONEL",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO15",
    DESCRIPTION: "ARMY - BRIGADIER",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO16",
    DESCRIPTION: "ARMY - MAJOR GENERAL",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO17",
    DESCRIPTION: "ARMY - LT. GENERAL",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO18",
    DESCRIPTION: "ARMY - GENERAL",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO19",
    DESCRIPTION: "AIRFORCE - FIRST LIEUTENANT",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO20",
    DESCRIPTION: "AIRFORCE - CAPTAIN",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO21",
    DESCRIPTION: "AIRFORCE - MAJOR",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO22",
    DESCRIPTION: "AIRFORCE - COMMANDANT",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO23",
    DESCRIPTION: "AIRFORCE - COLONEL",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO24",
    DESCRIPTION: "AIRFORCE - BRIGADIER",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO25",
    DESCRIPTION: "AIRFORCE - MAJOR GENERAL",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO26",
    DESCRIPTION: "AIRFORCE - LT. GENERAL",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO27",
    DESCRIPTION: "AIRFORCE - GENERAL",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO28",
    DESCRIPTION: "POLICE - CAPTAIN",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO29",
    DESCRIPTION: "POLICE - SUPERINTENDENT",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO30",
    DESCRIPTION: "POLICE - SNR. SUPERINTENDENT",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO31",
    DESCRIPTION: "POLICE - DIRECTOR",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO32",
    DESCRIPTION: "POLICE - ASST. COMMISSIONER",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MO34",
    DESCRIPTION: "POLICE - NATIONAL COMMISSIONER",
    XOCG_CODE: "MO00",
    CIS_CODE: "MO",
  },
  {
    CODE: "MP00",
    DESCRIPTION: "MEMBER OF PARLIAMENT",
    XOCG_CODE: "MP00",
    CIS_CODE: "MP",
  },
  {
    CODE: "NS01",
    DESCRIPTION: "NATIONAL SERVICEMEN",
    XOCG_CODE: "NS01",
    CIS_CODE: "NS",
  },
  {
    CODE: "OF00",
    DESCRIPTION: "OFFICE STAFF",
    XOCG_CODE: null,
    CIS_CODE: "OF",
  },
  {
    CODE: "OF01",
    DESCRIPTION: "BANK CLERK",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF02",
    DESCRIPTION: "BANK OFFICIAL",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF03",
    DESCRIPTION: "BOOKMAKER'S ASSISTANT",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF04",
    DESCRIPTION: "BOOKKEEPER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF05",
    DESCRIPTION: "CASHIER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF06",
    DESCRIPTION: "CIVIL SERVANT (NON EXEC.)",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF07",
    DESCRIPTION: "CLAIMS ADJUSTER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF08",
    DESCRIPTION: "CLERK",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF09",
    DESCRIPTION: "CLERICAL WORKER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF10",
    DESCRIPTION: "COLLECTOR (INSURANCE)",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF11",
    DESCRIPTION: "COMMUNICATIONS OFFICER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF12",
    DESCRIPTION: "COMPTOMETER OPERATOR",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF13",
    DESCRIPTION: "COMPUTER OPERATOR",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF14",
    DESCRIPTION: "CREDIT CLERK",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF15",
    DESCRIPTION: "CREDIT PERSONNEL",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF16",
    DESCRIPTION: "DESPATCHER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  { CODE: "OF17", DESCRIPTION: "EXAMINER", XOCG_CODE: "OF00", CIS_CODE: "OF" },
  {
    CODE: "OF18",
    DESCRIPTION: "GOVERNMENT OFFICER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF19",
    DESCRIPTION: "INSURANCE CLERK",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF20",
    DESCRIPTION: "KEYPUNCHER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF21",
    DESCRIPTION: "LOCAL GOVERNMENT OFFICER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF22",
    DESCRIPTION: "MARKET CHECKER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF23",
    DESCRIPTION: "NIGHT CLUB CASHIER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF24",
    DESCRIPTION: "OFFICE WORKER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF25",
    DESCRIPTION: "PAPER KEEPER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF26",
    DESCRIPTION: "PROOF READER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF27",
    DESCRIPTION: "PUBLIC SERVANT",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF28",
    DESCRIPTION: "PUNCH OPERATOR",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF29",
    DESCRIPTION: "PURCHASING AGENT",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF30",
    DESCRIPTION: "QUALITY CONTROL",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF31",
    DESCRIPTION: "PURCHASE CONTROL ADMINISTRATOR",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF32",
    DESCRIPTION: "RECEPTIONIST",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF33",
    DESCRIPTION: "RESERVATION AGENT",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF34",
    DESCRIPTION: "SALES CLERK",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF35",
    DESCRIPTION: "SALESLADY",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  { CODE: "OF36", DESCRIPTION: "SECRETARY", XOCG_CODE: "OF00", CIS_CODE: "OF" },
  {
    CODE: "OF37",
    DESCRIPTION: "SHIPPING CLERK",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF38",
    DESCRIPTION: "SHOP ASSISTANT",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF39",
    DESCRIPTION: "SHORTHAND TYPIST",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF40",
    DESCRIPTION: "STENOGRAPHER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF41",
    DESCRIPTION: "STEREOTYPER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF42",
    DESCRIPTION: "STOCK CLERK",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF43",
    DESCRIPTION: "STOCK CONTROLLER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  { CODE: "OF44", DESCRIPTION: "STOREMAN", XOCG_CODE: "OF00", CIS_CODE: "OF" },
  {
    CODE: "OF45",
    DESCRIPTION: "TELEGRAPHIST",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF46",
    DESCRIPTION: "TELEPHONE OPERATOR",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF47",
    DESCRIPTION: "TELEPHONIST",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF48",
    DESCRIPTION: "TICKET AGENT",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF49",
    DESCRIPTION: "TRACER",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF50",
    DESCRIPTION: "TRAFFIC CLERK",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF51",
    DESCRIPTION: "TRAVEL AGENT",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF52",
    DESCRIPTION: "TURF ACCOUNTANT",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF53",
    DESCRIPTION: "TYPIST",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF54",
    DESCRIPTION: "VDU OPERATOR",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF55",
    DESCRIPTION: "WAGE CLERK",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OF56",
    DESCRIPTION: "WEIGHTS AND MEASURES INSPECTOR",
    XOCG_CODE: "OF00",
    CIS_CODE: "OF",
  },
  {
    CODE: "OW",
    DESCRIPTION: "OUTSIDE WORKERS",
    XOCG_CODE: "OW",
    CIS_CODE: "OW",
  },
  {
    CODE: "PB00",
    DESCRIPTION: "PROFESSIONAL SELF EMPLOYED: BUSINESS",
    XOCG_CODE: null,
    CIS_CODE: "PB",
  },
  { CODE: "PB01", DESCRIPTION: "ACTUARY", XOCG_CODE: "PB00", CIS_CODE: "PB" },
  {
    CODE: "PB02",
    DESCRIPTION: "CHARTERED ACCOUNTANT",
    XOCG_CODE: "PB00",
    CIS_CODE: "PB",
  },
  {
    CODE: "PB03",
    DESCRIPTION: "BUSINESS CONSULTANT",
    XOCG_CODE: "PB00",
    CIS_CODE: "PB",
  },
  { CODE: "PE01", DESCRIPTION: "PENSIONER", XOCG_CODE: "PE01", CIS_CODE: "PE" },
  {
    CODE: "PG00",
    DESCRIPTION: "PROFESSIONAL SELF EMPLOYED: ENGINEERING",
    XOCG_CODE: null,
    CIS_CODE: "PG",
  },
  { CODE: "PG01", DESCRIPTION: "ARCHITECT", XOCG_CODE: "PG00", CIS_CODE: "PG" },
  {
    CODE: "PG02",
    DESCRIPTION: "CIVIL ENGINEER",
    XOCG_CODE: "PG00",
    CIS_CODE: "PG",
  },
  {
    CODE: "PG03",
    DESCRIPTION: "LAND SURVEYOR",
    XOCG_CODE: "PG00",
    CIS_CODE: "PG",
  },
  {
    CODE: "PG04",
    DESCRIPTION: "CHEMICAL ENGINEER",
    XOCG_CODE: "PG00",
    CIS_CODE: "PG",
  },
  {
    CODE: "PG05",
    DESCRIPTION: "ELECTRICAL ENGINEER",
    XOCG_CODE: "PG00",
    CIS_CODE: "PG",
  },
  {
    CODE: "PG06",
    DESCRIPTION: "QUANTITY SURVEYOR",
    XOCG_CODE: "PG00",
    CIS_CODE: "PG",
  },
  {
    CODE: "PL01",
    DESCRIPTION: "PROFESSIONAL SELF EMPLOYED: LEGAL",
    XOCG_CODE: null,
    CIS_CODE: "PL",
  },
  { CODE: "PL07", DESCRIPTION: "ADVOCATE", XOCG_CODE: "PL01", CIS_CODE: "PL" },
  {
    CODE: "PL08",
    DESCRIPTION: "BARRISTER",
    XOCG_CODE: "PL01",
    CIS_CODE: "PL",
  },
  { CODE: "PL09", DESCRIPTION: "SOLICITOR", XOCG_CODE: "PL01", CIS_CODE: "PL" },
  {
    CODE: "PL10",
    DESCRIPTION: "ATTORNEY",
    XOCG_CODE: "PL01",
    CIS_CODE: "PL",
  },
  { CODE: "PL11", DESCRIPTION: "LAWYER", XOCG_CODE: "PL01", CIS_CODE: "PL" },
  {
    CODE: "PM00",
    DESCRIPTION: "PROFESSIONAL SELF EMPLOYED: MEDICAL",
    XOCG_CODE: null,
    CIS_CODE: "PM",
  },
  {
    CODE: "PM01",
    DESCRIPTION: "ANAETHETIST",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM02",
    DESCRIPTION: "CLINICAL PSYCHOLOGIST",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  { CODE: "PM03", DESCRIPTION: "DENTIST", XOCG_CODE: "PM00", CIS_CODE: "PM" },
  {
    CODE: "PM04",
    DESCRIPTION: "MEDICAL PRACTIONER",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM05",
    DESCRIPTION: "OPTOMETRIST",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM06",
    DESCRIPTION: "PSYCHIATRIST",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM07",
    DESCRIPTION: "SPECIALIST (MEDICAL)",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM08",
    DESCRIPTION: "VETERINARIAN",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM09",
    DESCRIPTION: "CHIROPRACTOR",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  { CODE: "PM11", DESCRIPTION: "DOCTOR", XOCG_CODE: "PM00", CIS_CODE: "PM" },
  {
    CODE: "PM12",
    DESCRIPTION: "NEUROLOGIST (MEDICAL)",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM13",
    DESCRIPTION: "PHARMACIST",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM14",
    DESCRIPTION: "PSYCHOLOGIST",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  { CODE: "PM15", DESCRIPTION: "SURGEON", XOCG_CODE: "PM00", CIS_CODE: "PM" },
  {
    CODE: "PM16",
    DESCRIPTION: "PHYSIOTHERAPIST",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM17",
    DESCRIPTION: "GYNAECOLOGIST",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM18",
    DESCRIPTION: "OCCUPATION THERAPIST",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM19",
    DESCRIPTION: "RADIOGRAPHER",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM20",
    DESCRIPTION: "RADIOLOGIST",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM21",
    DESCRIPTION: "PATHOLOGIST",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM22",
    DESCRIPTION: "PEDIATRIST",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM23",
    DESCRIPTION: "OPTHALMOLOGIST",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "PM24",
    DESCRIPTION: "OBSTRETICIAN",
    XOCG_CODE: "PM00",
    CIS_CODE: "PM",
  },
  {
    CODE: "RE00",
    DESCRIPTION: "RELIGIOUS / CHARITABLE",
    XOCG_CODE: null,
    CIS_CODE: "RE",
  },
  {
    CODE: "RE01",
    DESCRIPTION: "BROTHER (RELIGIOUS)",
    XOCG_CODE: "RE00",
    CIS_CODE: "RE",
  },
  {
    CODE: "RE02",
    DESCRIPTION: "CHARITABLE BODY",
    XOCG_CODE: "RE00",
    CIS_CODE: "RE",
  },
  {
    CODE: "RE03",
    DESCRIPTION: "MONK",
    XOCG_CODE: "RE00",
    CIS_CODE: "RE",
  },
  { CODE: "RE04", DESCRIPTION: "PARSON", XOCG_CODE: "RE00", CIS_CODE: "RE" },
  {
    CODE: "RE05",
    DESCRIPTION: "RECTOR",
    XOCG_CODE: "RE00",
    CIS_CODE: "RE",
  },
  {
    CODE: "RE06",
    DESCRIPTION: "SISTER (RELIGIOUS)",
    XOCG_CODE: "RE00",
    CIS_CODE: "RE",
  },
  {
    CODE: "RE07",
    DESCRIPTION: "CLERGYMAN",
    XOCG_CODE: "RE00",
    CIS_CODE: "RE",
  },
  {
    CODE: "RE08",
    DESCRIPTION: "MINISTER OF RELIGION",
    XOCG_CODE: "RE00",
    CIS_CODE: "RE",
  },
  { CODE: "RE09", DESCRIPTION: "NUN", XOCG_CODE: "RE00", CIS_CODE: "RE" },
  {
    CODE: "RE10",
    DESCRIPTION: "PRIEST",
    XOCG_CODE: "RE00",
    CIS_CODE: "RE",
  },
  {
    CODE: "RE11",
    DESCRIPTION: "RELIGIOUS WORKER",
    XOCG_CODE: "RE00",
    CIS_CODE: "RE",
  },
  {
    CODE: "RE12",
    DESCRIPTION: "VICAR",
    XOCG_CODE: "RE00",
    CIS_CODE: "RE",
  },
  {
    CODE: "RE13",
    DESCRIPTION: "MISSIONARY",
    XOCG_CODE: "RE00",
    CIS_CODE: "RE",
  },
  {
    CODE: "RT01",
    DESCRIPTION: "RETIRED",
    XOCG_CODE: "RT01",
    CIS_CODE: "RT",
  },
  { CODE: "SA00", DESCRIPTION: "SALES", XOCG_CODE: null, CIS_CODE: "SA" },
  {
    CODE: "SA01",
    DESCRIPTION: "AGENT OR BROKER (INSURANCE)",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA02",
    DESCRIPTION: "COMMERCIAL TRAVELLER",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA03",
    DESCRIPTION: "COMPANY REPRESENTATIVE",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA04",
    DESCRIPTION: "ESTATE AGENT",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA05",
    DESCRIPTION: "FLORIST",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  { CODE: "SA06", DESCRIPTION: "HAWKER", XOCG_CODE: "SA00", CIS_CODE: "SA" },
  {
    CODE: "SA07",
    DESCRIPTION: "INSURANCE AGENT",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA08",
    DESCRIPTION: "MANUFACTURERS REPRESENTATIVE",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA09",
    DESCRIPTION: "MARKET TRADER",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA10",
    DESCRIPTION: "MERCHANT",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA11",
    DESCRIPTION: "REPRESENTATIVE",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA12",
    DESCRIPTION: "ROUNDSMAN",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA13",
    DESCRIPTION: "SALES ENGINEER",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA14",
    DESCRIPTION: "SALESMAN",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA15",
    DESCRIPTION: "SALES REPRESENTATIVE",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA16",
    DESCRIPTION: "SCRAP DEALER",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA17",
    DESCRIPTION: "SECONDHAND FURNITURE DEALER",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA18",
    DESCRIPTION: "SITE AGENT",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA19",
    DESCRIPTION: "STREET TRADER",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA20",
    DESCRIPTION: "WHOLESALER",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SA21",
    DESCRIPTION: "PAWN BROKER",
    XOCG_CODE: "SA00",
    CIS_CODE: "SA",
  },
  {
    CODE: "SB01",
    DESCRIPTION: "SEMI PROFESSIONAL & SALARIED PROFESSIONAL: BUSINESS",
    XOCG_CODE: null,
    CIS_CODE: "SB",
  },
  {
    CODE: "SB21",
    DESCRIPTION: "ACCOUNTANT",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB22",
    DESCRIPTION: "ANALYST",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB23",
    DESCRIPTION: "APPRAISER (OFFICIAL VALUER)",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB24",
    DESCRIPTION: "ARTICLE CLERK",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB25",
    DESCRIPTION: "ASSESSOR",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB26",
    DESCRIPTION: "AUCTIONEER",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB27",
    DESCRIPTION: "AUDITOR",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  { CODE: "SB28", DESCRIPTION: "BROKER", XOCG_CODE: "SB01", CIS_CODE: "SB" },
  {
    CODE: "SB29",
    DESCRIPTION: "CLAIMS INVESTIGATOR",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB30",
    DESCRIPTION: "COMPUTER PROGRAMMER",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB31",
    DESCRIPTION: "CONSULTANT",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB32",
    DESCRIPTION: "ECONOMIST (PRIVATE SECTOR)",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  { CODE: "SB33", DESCRIPTION: "ECONOMIST", XOCG_CODE: "SB01", CIS_CODE: "SB" },
  {
    CODE: "SB34",
    DESCRIPTION: "ESTIMATOR",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB35",
    DESCRIPTION: "INVESTOR (PRIVATE MEANS)",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB36",
    DESCRIPTION: "MARKET RESEARCHER",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB37",
    DESCRIPTION: "PERSONNEL OFFICER",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB38",
    DESCRIPTION: "PROGRAMMER",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB39",
    DESCRIPTION: "SPECULATOR (PRIVATE MEANS)",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB40",
    DESCRIPTION: "STORE ACCOUNTANT",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB41",
    DESCRIPTION: "SYSTEMS ANALYST",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB42",
    DESCRIPTION: "UNDERWRITER",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB43",
    DESCRIPTION: "COMPUTER TECHNICIAN",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB44",
    DESCRIPTION: "CHARTERED ACCOUNTANT (NOT SELF EMP)",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SB45",
    DESCRIPTION: "TOWN PLANNER",
    XOCG_CODE: "SB01",
    CIS_CODE: "SB",
  },
  {
    CODE: "SC01",
    DESCRIPTION: "SEMI PROFESSIONAL & SALARIED PROFESSIONAL: SCIENTIFIC",
    XOCG_CODE: null,
    CIS_CODE: "SC",
  },
  {
    CODE: "SC16",
    DESCRIPTION: "AGRONOMIST",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  {
    CODE: "SC17",
    DESCRIPTION: "ANTHROPOLOGIST",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  {
    CODE: "SC19",
    DESCRIPTION: "ARCHAEOLOGIST",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  {
    CODE: "SC20",
    DESCRIPTION: "BIOCHEMIST",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  { CODE: "SC21", DESCRIPTION: "BIOLOGIST", XOCG_CODE: "SC01", CIS_CODE: "SC" },
  {
    CODE: "SC22",
    DESCRIPTION: "BIOPHYSICIST CHEMICAL ANALYST",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  {
    CODE: "SC23",
    DESCRIPTION: "LABORATORY ASSISTANT",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  {
    CODE: "SC24",
    DESCRIPTION: "LABORATORY TECHNICIAN",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  {
    CODE: "SC25",
    DESCRIPTION: "MATHEMATICIAN",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  {
    CODE: "SC26",
    DESCRIPTION: "METALLURGIST",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  {
    CODE: "SC27",
    DESCRIPTION: "METALLURGICAL ANALYST",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  {
    CODE: "SC28",
    DESCRIPTION: "METEOROLOGIST",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  {
    CODE: "SC29",
    DESCRIPTION: "SCIENTIST",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  {
    CODE: "SC30",
    DESCRIPTION: "SOCIAL SCIENTIST",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  {
    CODE: "SC31",
    DESCRIPTION: "SOCIOLOGIST",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  {
    CODE: "SC32",
    DESCRIPTION: "STATISTICIAN",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  {
    CODE: "SC33",
    DESCRIPTION: "ZOOLOGIST",
    XOCG_CODE: "SC01",
    CIS_CODE: "SC",
  },
  { CODE: "SC34", DESCRIPTION: "GEOLOGIST", XOCG_CODE: "SC01", CIS_CODE: "SC" },
  {
    CODE: "SE00",
    DESCRIPTION: "SEMI PROFESSIONAL & SALARIED PROFESSIONAL: EDUCATION",
    XOCG_CODE: null,
    CIS_CODE: "SE",
  },
  { CODE: "SE01", DESCRIPTION: "LECTURER", XOCG_CODE: "SE00", CIS_CODE: "SE" },
  {
    CODE: "SE02",
    DESCRIPTION: "RESEARCH ASSISTANT",
    XOCG_CODE: "SE00",
    CIS_CODE: "SE",
  },
  {
    CODE: "SE03",
    DESCRIPTION: "RESEARCHER",
    XOCG_CODE: "SE00",
    CIS_CODE: "SE",
  },
  {
    CODE: "SE04",
    DESCRIPTION: "TEACHER",
    XOCG_CODE: "SE00",
    CIS_CODE: "SE",
  },
  {
    CODE: "SE05",
    DESCRIPTION: "TRAINING OFFICER",
    XOCG_CODE: "SE00",
    CIS_CODE: "SE",
  },
  {
    CODE: "SE06",
    DESCRIPTION: "UNIVERSITY DEAN",
    XOCG_CODE: "SE00",
    CIS_CODE: "SE",
  },
  {
    CODE: "SE07",
    DESCRIPTION: "UNIVERSITY LECTURER",
    XOCG_CODE: "SE00",
    CIS_CODE: "SE",
  },
  {
    CODE: "SE08",
    DESCRIPTION: "UNIVERSITY PROFESSOR",
    XOCG_CODE: "SE00",
    CIS_CODE: "SE",
  },
  { CODE: "SE09", DESCRIPTION: "PRINCIPAL", XOCG_CODE: "SE00", CIS_CODE: "SE" },
  {
    CODE: "SE10",
    DESCRIPTION: "BURSAR",
    XOCG_CODE: "SE00",
    CIS_CODE: "SE",
  },
  {
    CODE: "SG00",
    DESCRIPTION: "SEMI PROFESSIONAL & SALARIED PROFESSIONAL: ENGINEERING",
    XOCG_CODE: null,
    CIS_CODE: "SG",
  },
  {
    CODE: "SG01",
    DESCRIPTION: "ENGINEERING",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG02",
    DESCRIPTION: "CABLE DISIGNER ENGINEER",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG03",
    DESCRIPTION: "CIVIL ENGINEER'S AIDE",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG04",
    DESCRIPTION: "COMMUNICATION ENGINEER",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG05",
    DESCRIPTION: "DESIGN ENGINEER",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG06",
    DESCRIPTION: "DRAUGHTSMAN",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG07",
    DESCRIPTION: "ENGINEER'S AIDE",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG08",
    DESCRIPTION: "ENGINEERING DESIGNER",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG09",
    DESCRIPTION: "INSTRUMENT MAKER",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG10",
    DESCRIPTION: "PLANNING ENGINEER",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG11",
    DESCRIPTION: "P.O. ENGINEER",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG12",
    DESCRIPTION: "SITE ENGINEER",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG13",
    DESCRIPTION: "TECHNICAL ENGINEER",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG14",
    DESCRIPTION: "TECHNICIANS",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG15",
    DESCRIPTION: "TELECOMMUNICATIONS ENGINEER",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG16",
    DESCRIPTION: "TELEPHONE ENGINEER",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SG17",
    DESCRIPTION: "WORK STUDY ENGINEER",
    XOCG_CODE: "SG00",
    CIS_CODE: "SG",
  },
  {
    CODE: "SK01",
    DESCRIPTION: "SEMI-SKILLED",
    XOCG_CODE: null,
    CIS_CODE: "SK",
  },
  {
    CODE: "SK02",
    DESCRIPTION: "BARGEMAN",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK04",
    DESCRIPTION: "COMMERCIAL FISHERMAN",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK05",
    DESCRIPTION: "DAIRYMAN (WORKER IN DAIRY)",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK06",
    DESCRIPTION: "FARMWORKER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK07",
    DESCRIPTION: "FILE OPERATOR",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  { CODE: "SK08", DESCRIPTION: "FISHERMAN", XOCG_CODE: "SK01", CIS_CODE: "SK" },
  {
    CODE: "SK09",
    DESCRIPTION: "FOLLMANNER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK10",
    DESCRIPTION: "FOREST WORKER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK11",
    DESCRIPTION: "FORESTRY WORKER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK12",
    DESCRIPTION: "GAME RANGER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK13",
    DESCRIPTION: "HEDGER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  { CODE: "SK14", DESCRIPTION: "HERDSMAN", XOCG_CODE: "SK01", CIS_CODE: "SK" },
  {
    CODE: "SK15",
    DESCRIPTION: "LOGGER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK16",
    DESCRIPTION: "LONGSHOREMAN",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK17",
    DESCRIPTION: "MARKET GARDENER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK18",
    DESCRIPTION: "MERCHANT MARINE SHIP PERSONNEL",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK19",
    DESCRIPTION: "NURSERYMAN",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK20",
    DESCRIPTION: "PARK WARDEN",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK21",
    DESCRIPTION: "PARK KEEPER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK22",
    DESCRIPTION: "SAWYER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  { CODE: "SK23", DESCRIPTION: "SHOPPER", XOCG_CODE: "SK01", CIS_CODE: "SK" },
  {
    CODE: "SK24",
    DESCRIPTION: "SLATE WORKER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK25",
    DESCRIPTION: "STONE CUTTER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK26",
    DESCRIPTION: "TIMBERMILL WORKER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK27",
    DESCRIPTION: "AMBULANCE DRIVER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK28",
    DESCRIPTION: "BREAD VAN DRIVER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  { CODE: "SK29", DESCRIPTION: "BUSDRIVER", XOCG_CODE: "SK01", CIS_CODE: "SK" },
  {
    CODE: "SK30",
    DESCRIPTION: "CAR TRANSPORTER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK31",
    DESCRIPTION: "COAL TRUCK DRIVER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK32",
    DESCRIPTION: "DELIVERY DRIVER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  { CODE: "SK33", DESCRIPTION: "DRAYMAN", XOCG_CODE: "SK01", CIS_CODE: "SK" },
  {
    CODE: "SK34",
    DESCRIPTION: "DRIVER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK35",
    DESCRIPTION: "DRIVING INSTRUCTOR",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK36",
    DESCRIPTION: "LORRY DRIVER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK37",
    DESCRIPTION: "MILK TANKER DRIVER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK38",
    DESCRIPTION: "TAXI DRIVER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK39",
    DESCRIPTION: "TRUCK DRIVER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK40",
    DESCRIPTION: "VAN DRIVER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SK41",
    DESCRIPTION: "VEHICLE DRIVER",
    XOCG_CODE: "SK01",
    CIS_CODE: "SK",
  },
  {
    CODE: "SL01",
    DESCRIPTION: "SEMI PROFESSIONAL & SALARIED PROFESSIONAL: LEGAL",
    XOCG_CODE: null,
    CIS_CODE: "SL",
  },
  {
    CODE: "SL42",
    DESCRIPTION: "ARBITRATOR",
    XOCG_CODE: "SL01",
    CIS_CODE: "SL",
  },
  {
    CODE: "SL43",
    DESCRIPTION: "BAILIFF",
    XOCG_CODE: "SL01",
    CIS_CODE: "SL",
  },
  {
    CODE: "SL44",
    DESCRIPTION: "LEGAL OFFICER",
    XOCG_CODE: "SL01",
    CIS_CODE: "SL",
  },
  {
    CODE: "SL45",
    DESCRIPTION: "MAGISTRATE",
    XOCG_CODE: "SL01",
    CIS_CODE: "SL",
  },
  {
    CODE: "SL46",
    DESCRIPTION: "PARA LEGAL",
    XOCG_CODE: "SL01",
    CIS_CODE: "SL",
  },
  {
    CODE: "SL47",
    DESCRIPTION: "JUDGE",
    XOCG_CODE: "SL01",
    CIS_CODE: "SL",
  },
  {
    CODE: "SM00",
    DESCRIPTION: "SEMI PROFESSIONAL & SALARIED PROFESSIONAL: MEDICAL",
    XOCG_CODE: null,
    CIS_CODE: "SM",
  },
  {
    CODE: "SM01",
    DESCRIPTION: "DENTAL HYGIENIST",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM02",
    DESCRIPTION: "DENTAL THERAPIST",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM03",
    DESCRIPTION: "DENTAL TECHNICIAN",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM04",
    DESCRIPTION: "DIETICIAN",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM05",
    DESCRIPTION: "DISPENSER (CHEMIST)",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM06",
    DESCRIPTION: "HEALTH VISITOR",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM07",
    DESCRIPTION: "MEDICAL ASSISTANT",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  { CODE: "SM08", DESCRIPTION: "MIDWIFE", XOCG_CODE: "SM00", CIS_CODE: "SM" },
  {
    CODE: "SM09",
    DESCRIPTION: "MORTICIAN",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM10",
    DESCRIPTION: "NURSING OFFICER",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM11",
    DESCRIPTION: "NURSING SISTER",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  { CODE: "SM12", DESCRIPTION: "OPTICIAN", XOCG_CODE: "SM00", CIS_CODE: "SM" },
  {
    CODE: "SM13",
    DESCRIPTION: "OSTEOPATH (PRACTITIONER)",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM14",
    DESCRIPTION: "STAFF NURSE",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM15",
    DESCRIPTION: "STATE ENROLLED NURSE",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM16",
    DESCRIPTION: "STATE REGISTERED NURSE",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM17",
    DESCRIPTION: "PARA MEDIC",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM18",
    DESCRIPTION: "MEDICAL SUPERINTENDANT",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM19",
    DESCRIPTION: "ORAL HYGIENIST",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM20",
    DESCRIPTION: "HOMEOPATH",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM21",
    DESCRIPTION: "THEATRE SISTER",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM22",
    DESCRIPTION: "PROFESSIONAL NURSE",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  {
    CODE: "SM23",
    DESCRIPTION: "MEDICAL TECHNOLOGIST",
    XOCG_CODE: "SM00",
    CIS_CODE: "SM",
  },
  { CODE: "SR00", DESCRIPTION: "SERVICE", XOCG_CODE: null, CIS_CODE: "SR" },
  {
    CODE: "SR01",
    DESCRIPTION: "AMBULANCE MAN",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR02",
    DESCRIPTION: "ATTENDANT (HOSPITAL)",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  { CODE: "SR03", DESCRIPTION: "AU PAIR", XOCG_CODE: "SR00", CIS_CODE: "SR" },
  {
    CODE: "SR04",
    DESCRIPTION: "BAKER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR05",
    DESCRIPTION: "BANK MESSENGER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR06",
    DESCRIPTION: "BARBER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  { CODE: "SR07", DESCRIPTION: "BARMAID", XOCG_CODE: "SR00", CIS_CODE: "SR" },
  {
    CODE: "SR08",
    DESCRIPTION: "BARMAN",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR09",
    DESCRIPTION: "BEAUTICIAN",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR10",
    DESCRIPTION: "BINGO CALLER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  { CODE: "SR11", DESCRIPTION: "BLOCKMAN", XOCG_CODE: "SR00", CIS_CODE: "SR" },
  {
    CODE: "SR12",
    DESCRIPTION: "BUTCHER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  { CODE: "SR13", DESCRIPTION: "CHEF", XOCG_CODE: "SR00", CIS_CODE: "SR" },
  {
    CODE: "SR14",
    DESCRIPTION: "CLUB ASSISTANT",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  { CODE: "SR15", DESCRIPTION: "COOK", XOCG_CODE: "SR00", CIS_CODE: "SR" },
  {
    CODE: "SR16",
    DESCRIPTION: "COURIER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  { CODE: "SR17", DESCRIPTION: "CROUPIER", XOCG_CODE: "SR00", CIS_CODE: "SR" },
  {
    CODE: "SR18",
    DESCRIPTION: "DENTAL ASSISTANT",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR19",
    DESCRIPTION: "FOOD HANDLER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR20",
    DESCRIPTION: "HAIRDRESSER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR21",
    DESCRIPTION: "HOSPITAL ATTENDANT",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR22",
    DESCRIPTION: "HOSPITAL WORKER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR23",
    DESCRIPTION: "HOUSE HELP",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR24",
    DESCRIPTION: "HOUSEKEEPER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR25",
    DESCRIPTION: "KITCHEN PORTER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR26",
    DESCRIPTION: "LAUNDRYMAN",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR27",
    DESCRIPTION: "LAUNDRY WORKER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR28",
    DESCRIPTION: "LIFT OPERATOR",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR29",
    DESCRIPTION: "MEAT CUTTER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR30",
    DESCRIPTION: "MESSENGER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR31",
    DESCRIPTION: "METER READER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR32",
    DESCRIPTION: "MUSEUM WORKER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR33",
    DESCRIPTION: "NIGHTCLUB DOORMAN",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR34",
    DESCRIPTION: "NURSE",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR35",
    DESCRIPTION: "NURSERY NURSE",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR36",
    DESCRIPTION: "ORDERLY",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  { CODE: "SR37", DESCRIPTION: "PORTER", XOCG_CODE: "SR00", CIS_CODE: "SR" },
  {
    CODE: "SR38",
    DESCRIPTION: "RAILWAY PORTER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR39",
    DESCRIPTION: "RESTAURANT",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR40",
    DESCRIPTION: "SLAUGHTERMAN",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  { CODE: "SR41", DESCRIPTION: "STEWARD", XOCG_CODE: "SR00", CIS_CODE: "SR" },
  {
    CODE: "SR42",
    DESCRIPTION: "STEWARDESS",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR43",
    DESCRIPTION: "THEATRE ATTENDANT",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR44",
    DESCRIPTION: "TICKET COLLECTOR",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  { CODE: "SR45", DESCRIPTION: "USHER", XOCG_CODE: "SR00", CIS_CODE: "SR" },
  {
    CODE: "SR46",
    DESCRIPTION: "WAITER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  { CODE: "SR47", DESCRIPTION: "WAITRESS", XOCG_CODE: "SR00", CIS_CODE: "SR" },
  {
    CODE: "SR48",
    DESCRIPTION: "WARDEN",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR49",
    DESCRIPTION: "ZOO KEEPER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR50",
    DESCRIPTION: "AIR HOSTESS",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR51",
    DESCRIPTION: "ACUPUNCTURIST",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR52",
    DESCRIPTION: "DEBT COLLECTOR",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SR53",
    DESCRIPTION: "PRIVATE INVESTIGATOR",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  { CODE: "SR54", DESCRIPTION: "CORONER", XOCG_CODE: "SR00", CIS_CODE: "SR" },
  {
    CODE: "SR55",
    DESCRIPTION: "GROUND HOSTESS",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  { CODE: "SR56", DESCRIPTION: "JOCKEY", XOCG_CODE: "SR00", CIS_CODE: "SR" },
  {
    CODE: "SR57",
    DESCRIPTION: "PERSONAL TRAINER",
    XOCG_CODE: "SR00",
    CIS_CODE: "SR",
  },
  {
    CODE: "SS01",
    DESCRIPTION: "SEMI PROFESSIONAL & SALARIED PROFESSIONAL: GOVERNMENT",
    XOCG_CODE: null,
    CIS_CODE: "SS",
  },
  {
    CODE: "SS02",
    DESCRIPTION: "CITY PLANNING OFFICER",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  { CODE: "SS03", DESCRIPTION: "CURATOR", XOCG_CODE: "SS01", CIS_CODE: "SS" },
  {
    CODE: "SS04",
    DESCRIPTION: "CUSTODIAN (MUSEUM)",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  {
    CODE: "SS05",
    DESCRIPTION: "CUSTOMS OFFICER",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  {
    CODE: "SS06",
    DESCRIPTION: "DETECTIVE",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  {
    CODE: "SS07",
    DESCRIPTION: "ECONOMIST (GOVERNMENT)",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  {
    CODE: "SS08",
    DESCRIPTION: "IMMIGRATION OFFICER",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  {
    CODE: "SS09",
    DESCRIPTION: "INTERPRETER",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  {
    CODE: "SS10",
    DESCRIPTION: "LIBRARIAN",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  {
    CODE: "SS11",
    DESCRIPTION: "LIBRARY ASSISTANT",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  {
    CODE: "SS12",
    DESCRIPTION: "POLITICIAN",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  {
    CODE: "SS13",
    DESCRIPTION: "SOCIAL WELFARE",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  {
    CODE: "SS14",
    DESCRIPTION: "TAX ASSESSOR",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  {
    CODE: "SS15",
    DESCRIPTION: "TAX INSPECTOR",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  {
    CODE: "SS16",
    DESCRIPTION: "CORRECTION  OFFICER",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  {
    CODE: "SS17",
    DESCRIPTION: "OCCUPATION",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  {
    CODE: "SS18",
    DESCRIPTION: "MEMBER OF PARLIAMENT",
    XOCG_CODE: "SS01",
    CIS_CODE: "SS",
  },
  { CODE: "SS19", DESCRIPTION: "MAYOR", XOCG_CODE: "SS01", CIS_CODE: "SS" },
  {
    CODE: "ST00",
    DESCRIPTION: "SEMI PROFESSIONAL & SALARIED PROFESSIONAL: TRANSPORT",
    XOCG_CODE: null,
    CIS_CODE: "ST",
  },
  { CODE: "ST34", DESCRIPTION: "CO-PILOT", XOCG_CODE: "ST00", CIS_CODE: "ST" },
  {
    CODE: "ST35",
    DESCRIPTION: "FLIGHT CONTROLLER",
    XOCG_CODE: "ST00",
    CIS_CODE: "ST",
  },
  {
    CODE: "ST36",
    DESCRIPTION: "FLIGHT ENGINEER",
    XOCG_CODE: "ST00",
    CIS_CODE: "ST",
  },
  {
    CODE: "ST37",
    DESCRIPTION: "MARINE ENGINEER",
    XOCG_CODE: "ST00",
    CIS_CODE: "ST",
  },
  {
    CODE: "ST38",
    DESCRIPTION: "PILOT (AIRLINE PILOT IS EXECUTIVE)",
    XOCG_CODE: "ST00",
    CIS_CODE: "ST",
  },
  {
    CODE: "ST39",
    DESCRIPTION: "RADAR OPERATOR",
    XOCG_CODE: "ST00",
    CIS_CODE: "ST",
  },
  {
    CODE: "SU01",
    DESCRIPTION: "STUDENT",
    XOCG_CODE: "SU01",
    CIS_CODE: "SU",
  },
  { CODE: "TR00", DESCRIPTION: "TRADES", XOCG_CODE: null, CIS_CODE: "TR" },
  {
    CODE: "TR01",
    DESCRIPTION: "AA MAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR02",
    DESCRIPTION: "ALARM SERVICE ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR03",
    DESCRIPTION: "APPLIANCE REPAIRMAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR04", DESCRIPTION: "ASPHALTER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR05",
    DESCRIPTION: "ASSEMBLER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR06",
    DESCRIPTION: "ASSISTANT DISTRIBUTION ENG.",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR07",
    DESCRIPTION: "ASSISTANT TO THE TRADESMAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR08",
    DESCRIPTION: "AUTOBODY REPAIRMAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR09",
    DESCRIPTION: "AUXILIARY ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR10",
    DESCRIPTION: "BLACKSMITH",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR11",
    DESCRIPTION: "BLEACHER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR12", DESCRIPTION: "BLENDER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR14",
    DESCRIPTION: "BOILERMAKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR15",
    DESCRIPTION: "BOOKBINDER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR16",
    DESCRIPTION: "BOTTLER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR17", DESCRIPTION: "BRAKEMAN", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR18",
    DESCRIPTION: "BRAZIER (BRASS WORKER)",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR19", DESCRIPTION: "BREWER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR1A",
    DESCRIPTION: "METAL WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR1B",
    DESCRIPTION: "METAL DRAWER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR1C",
    DESCRIPTION: "MILL OPERATOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR1D", DESCRIPTION: "MILLER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR1E",
    DESCRIPTION: "MILLWRIGHT",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR1F", DESCRIPTION: "MINER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR1G",
    DESCRIPTION: "MOULDER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR1H", DESCRIPTION: "NET MAKER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR1I",
    DESCRIPTION: "NUCLEAR ENERGY WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR1J", DESCRIPTION: "OPENER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR1K",
    DESCRIPTION: "OPERATING ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR1L", DESCRIPTION: "OPERATOR", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR1M",
    DESCRIPTION: "OVEN MAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR1N",
    DESCRIPTION: "OVERLOADER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR1O",
    DESCRIPTION: "OVERLOCKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR1P",
    DESCRIPTION: "PAINTER (HOUSE)",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR1Q",
    DESCRIPTION: "PAINTSPRAYER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR1R",
    DESCRIPTION: "PANELBEATER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR1S",
    DESCRIPTION: "PAPER TAPE OPERATOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR1T", DESCRIPTION: "PERCHER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR1U",
    DESCRIPTION: "PIPE FITTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR1V",
    DESCRIPTION: "PLANT ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR1W",
    DESCRIPTION: "PLANT OPERATOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR1X", DESCRIPTION: "PLASTERER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR1Y",
    DESCRIPTION: "PLATER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR1Z",
    DESCRIPTION: "PLASTIC MAKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR20",
    DESCRIPTION: "BREWERY WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR21",
    DESCRIPTION: "BRICKLAYER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR22",
    DESCRIPTION: "BUILDER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR23",
    DESCRIPTION: "BUILDING ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR24",
    DESCRIPTION: "BURLING AND MENDING WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR25", DESCRIPTION: "BURNER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR26",
    DESCRIPTION: "BUS CONDUCTOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR27",
    DESCRIPTION: "BUS INSPECTOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR28",
    DESCRIPTION: "CABINET MAKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR29",
    DESCRIPTION: "CABLE JOINER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2A",
    DESCRIPTION: "PLUMBER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2B",
    DESCRIPTION: "POOL TABLE MAINTENANCE",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2C",
    DESCRIPTION: "P O WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2D",
    DESCRIPTION: "POLISHER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR2E", DESCRIPTION: "POTTER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR2F",
    DESCRIPTION: "POULTRY/MEAT INSPECTOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2H",
    DESCRIPTION: "PRECISION INSTRUMENT MAKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR2I", DESCRIPTION: "PRESSER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR2J",
    DESCRIPTION: "PRESSMAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2K",
    DESCRIPTION: "PRESS OPERATOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2L",
    DESCRIPTION: "PRINTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2M",
    DESCRIPTION: "PRINTING WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2N",
    DESCRIPTION: "PRINTER ASSISTANT",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2O",
    DESCRIPTION: "PRINT FINISHER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2P",
    DESCRIPTION: "PROCESS ENGRAVER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2Q",
    DESCRIPTION: "PROCESS WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2R",
    DESCRIPTION: "PRODUCTION CONTROL",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2S",
    DESCRIPTION: "PRODUCTION LINE STAFF",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2T",
    DESCRIPTION: "PROGRESS CHASER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2U",
    DESCRIPTION: "PROGRESS LIAISON OFFICER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR2V", DESCRIPTION: "PURIFIER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR2W",
    DESCRIPTION: "PUTTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2X",
    DESCRIPTION: "QUALITY CONTROLLER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2Y",
    DESCRIPTION: "RAILWAY DRIVER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR2Z",
    DESCRIPTION: "RAILWAY FIREMAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR30",
    DESCRIPTION: "CAPSTAN SECTAR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR31", DESCRIPTION: "CARDER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR32",
    DESCRIPTION: "CAR SPRAYER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR33", DESCRIPTION: "CARPENTER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR34",
    DESCRIPTION: "CARPET FITTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR35",
    DESCRIPTION: "CASTING WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR36",
    DESCRIPTION: "CEMENT RENDERER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR38",
    DESCRIPTION: "CHIMNEY SWEEP",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR39",
    DESCRIPTION: "COACH BUILDER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3A",
    DESCRIPTION: "RAILWAY SHUNTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3B",
    DESCRIPTION: "RAILWAY WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3C",
    DESCRIPTION: "REFRIGERATION ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR3D", DESCRIPTION: "RENOVATOR", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR3E",
    DESCRIPTION: "REPAIRMAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3F",
    DESCRIPTION: "RIGGER (SCAFFOLDER)",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR3G", DESCRIPTION: "RIVETER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR3H",
    DESCRIPTION: "ROOFER (TITLE)",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR3I", DESCRIPTION: "SAGGERMAN", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR3J",
    DESCRIPTION: "SCAFFOLDER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3K",
    DESCRIPTION: "SEAMSTRESS",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3L",
    DESCRIPTION: "SERVICE ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3M",
    DESCRIPTION: "SERVICEMAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3N",
    DESCRIPTION: "SETTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3O",
    DESCRIPTION: "SHEET METAL WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3P",
    DESCRIPTION: "SHIPBUILDER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3Q",
    DESCRIPTION: "SHIPWRIGHTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3R",
    DESCRIPTION: "SHOEMAKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3S",
    DESCRIPTION: "SHOE REPAIRER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3T",
    DESCRIPTION: "SHOP STEWARD",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR3U", DESCRIPTION: "SHUNTER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR3V",
    DESCRIPTION: "SILVERSMITH",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3W",
    DESCRIPTION: "SKILLED APPRENTICE",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR3X",
    DESCRIPTION: "SKIVER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR3Y", DESCRIPTION: "SLINGER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR3Z",
    DESCRIPTION: "SLITTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR40",
    DESCRIPTION: "COMBING WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR41",
    DESCRIPTION: "COMPOSITOR TYPESETTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR42", DESCRIPTION: "CONDUCTOR", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR43",
    DESCRIPTION: "CONTRACTOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR44",
    DESCRIPTION: "COPPERSMITH",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR45",
    DESCRIPTION: "CRAFTSMAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR46",
    DESCRIPTION: "CRANE OPERATOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR47",
    DESCRIPTION: "CUTTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR48",
    DESCRIPTION: "DIE CASTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR49",
    DESCRIPTION: "DOFFER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR4A", DESCRIPTION: "SMELTER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR4B",
    DESCRIPTION: "SPINNER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4C",
    DESCRIPTION: "SPRAYPAINTER WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4D",
    DESCRIPTION: "STATIONERY ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4E",
    DESCRIPTION: "STEEL ERECTOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4F",
    DESCRIPTION: "STEEL WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4G",
    DESCRIPTION: "STEEPLEJACK",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4H",
    DESCRIPTION: "STURGER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR4I", DESCRIPTION: "SWITCHMAN", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR4J",
    DESCRIPTION: "TAILOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR4K", DESCRIPTION: "TANNER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR4L",
    DESCRIPTION: "TELEPHONE SERVICEMAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4M",
    DESCRIPTION: "TELEVISION ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR4N", DESCRIPTION: "TESTER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR4O",
    DESCRIPTION: "TEST ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4P",
    DESCRIPTION: "TEXTILE PRINTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4Q",
    DESCRIPTION: "TEXTILE WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR4R", DESCRIPTION: "TILER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR4S",
    DESCRIPTION: "TILE SETTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4T",
    DESCRIPTION: "TIN PLATE WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4U",
    DESCRIPTION: "TINSMITH",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4V",
    DESCRIPTION: "TOOL AND DIE MAKER (TURNER)",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4W",
    DESCRIPTION: "TOOL GRINDER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4X",
    DESCRIPTION: "TOOLMAKER - ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4Y",
    DESCRIPTION: "TOOLROOM FITTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR4Z",
    DESCRIPTION: "TOOLSETTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR50",
    DESCRIPTION: "DRESSMAKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR51",
    DESCRIPTION: "DRYWALL FINISHER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR52", DESCRIPTION: "DYER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR53",
    DESCRIPTION: "ELECTRICIAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR54",
    DESCRIPTION: "ELECTRICAL FITTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR55",
    DESCRIPTION: "ELECTROPLATER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR56",
    DESCRIPTION: "ENGINE DRIVER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR57",
    DESCRIPTION: "ENGRAVER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR58", DESCRIPTION: "ERECTOR", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR59",
    DESCRIPTION: "FABRICATOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5A",
    DESCRIPTION: "TRAIN ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5B",
    DESCRIPTION: "TRIMMER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5C",
    DESCRIPTION: "TUBE DRIVER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5D",
    DESCRIPTION: "TUBE MILL OPERATOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR5E", DESCRIPTION: "TURNER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR5F",
    DESCRIPTION: "TYPESETTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5G",
    DESCRIPTION: "TYREFITTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5H",
    DESCRIPTION: "TV OR RADIO REPAIRMAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5I",
    DESCRIPTION: "TWINE MAKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5J",
    DESCRIPTION: "TWISTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5K",
    DESCRIPTION: "UPHOLSTERER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5L",
    DESCRIPTION: "VEHICLE BUILDER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5M",
    DESCRIPTION: "WAGON BUILDER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5N",
    DESCRIPTION: "WAGON REPAIRER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5O",
    DESCRIPTION: "WARP TWISTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5P",
    DESCRIPTION: "WATCH MAKER REPAIRER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5Q",
    DESCRIPTION: "WATCH REPAIRER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5R",
    DESCRIPTION: "WEAVER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR5S", DESCRIPTION: "WELDER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR5T",
    DESCRIPTION: "WINDER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5U",
    DESCRIPTION: "WIRE DRAWER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5V",
    DESCRIPTION: "WOOD WORKING MACHINIST",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5W",
    DESCRIPTION: "WOOLSORTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5X",
    DESCRIPTION: "ARTISAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR5Y",
    DESCRIPTION: "APPRENTICE",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR60",
    DESCRIPTION: "FACTORY WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR61",
    DESCRIPTION: "FARM ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR62",
    DESCRIPTION: "FARRIER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR63",
    DESCRIPTION: "FAULTFINDER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR64",
    DESCRIPTION: "FELLER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR65",
    DESCRIPTION: "FENCE ERECTOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR66",
    DESCRIPTION: "FELTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR67", DESCRIPTION: "FINISHER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR68",
    DESCRIPTION: "FIRER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR69", DESCRIPTION: "FITTER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR70",
    DESCRIPTION: "FLOOR LAYER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR71",
    DESCRIPTION: "FLOOR TILER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR72",
    DESCRIPTION: "FORKLIFT TRUCK DRIVER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR73",
    DESCRIPTION: "FOUNDRYMAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR74",
    DESCRIPTION: "FURNACEMAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR75",
    DESCRIPTION: "GARAGE REPAIRMAN",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR76",
    DESCRIPTION: "GAS ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR77",
    DESCRIPTION: "GAS FITTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR78",
    DESCRIPTION: "GLASSBLOWER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR79", DESCRIPTION: "GLAZIER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR80",
    DESCRIPTION: "GOLDSMITH",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR81", DESCRIPTION: "GRINDER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR82",
    DESCRIPTION: "GUNSMITH",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR83",
    DESCRIPTION: "HEATING ENGINEER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR84",
    DESCRIPTION: "HEAVY EQUIPMENT",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR85",
    DESCRIPTION: "HOIST OPERATOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR86",
    DESCRIPTION: "INSPECTOR",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR87", DESCRIPTION: "INSTALLER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR88",
    DESCRIPTION: "INVISIBLE MENDER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR89",
    DESCRIPTION: "IRON WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR90",
    DESCRIPTION: "JOBBER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR91", DESCRIPTION: "JOINER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR92",
    DESCRIPTION: "JOINTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR93", DESCRIPTION: "KILNMAN", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR94",
    DESCRIPTION: "KNITTER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR95", DESCRIPTION: "MALTSTER", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR96",
    DESCRIPTION: "MASON",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  { CODE: "TR97", DESCRIPTION: "MECHANIC", XOCG_CODE: "TR00", CIS_CODE: "TR" },
  {
    CODE: "TR98",
    DESCRIPTION: "MECHANICAL ENGINEER (MOTOR)",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "TR99",
    DESCRIPTION: "METAL PLATE WORKER",
    XOCG_CODE: "TR00",
    CIS_CODE: "TR",
  },
  {
    CODE: "UN01",
    DESCRIPTION: "UNEMPLOYED",
    XOCG_CODE: "UN01",
    CIS_CODE: "UN",
  },
  {
    CODE: "UNK",
    DESCRIPTION: "USED TO DEFINE COMPANY",
    XOCG_CODE: null,
    CIS_CODE: null,
  },
  {
    CODE: "UNK9",
    DESCRIPTION: "CONVERSION UNKNOWN",
    XOCG_CODE: null,
    CIS_CODE: null,
  },
  {
    CODE: "ZZ01",
    DESCRIPTION: "ADVENTURER",
    XOCG_CODE: "ZZ01",
    CIS_CODE: "ZZ",
  },
  { CODE: "ZZ02", DESCRIPTION: "BOOKMAKER", XOCG_CODE: "ZZ02", CIS_CODE: "ZZ" },
  {
    CODE: "ZZ03",
    DESCRIPTION: "CLAIRVOYANT",
    XOCG_CODE: "ZZ03",
    CIS_CODE: "ZZ",
  },
  { CODE: "ZZ04", DESCRIPTION: "EXPLORER", XOCG_CODE: "ZZ04", CIS_CODE: "ZZ" },
  {
    CODE: "ZZ05",
    DESCRIPTION: "GAMBLER",
    XOCG_CODE: "ZZ05",
    CIS_CODE: "ZZ",
  },
  {
    CODE: "ZZ99",
    DESCRIPTION: "DIRECT AXIS UNKNOWN",
    XOCG_CODE: "ZZ99",
    CIS_CODE: "ZZ",
  },
];

export const employerTypes = [
  {
    CODE: "007",
    DESCRIPTION: "NON-PROFIT ORGANISATIONS",
    XEMG_CODE: "NPO",
  },
  { CODE: "011", DESCRIPTION: "AGRICULTURE - MAIZE", XEMG_CODE: "AGRI" },
  {
    CODE: "021",
    DESCRIPTION: "AGRICULTURE - OTHER CEREALS",
    XEMG_CODE: "AGRI",
  },
  {
    CODE: "041",
    DESCRIPTION: "AGRICULTURE - WOOL MOHAIR & PELTS",
    XEMG_CODE: "AGRI",
  },
  {
    CODE: "051",
    DESCRIPTION: "AGRICULTURE - CATTLE SHEEP, PIGS",
    XEMG_CODE: "AGRI",
  },
  {
    CODE: "061",
    DESCRIPTION: "AGRICULTURE - OTHER LIVESTOCK",
    XEMG_CODE: "AGRI",
  },
  {
    CODE: "071",
    DESCRIPTION: "AGRICULTURE - FRUIT & VEGETABLES",
    XEMG_CODE: "AGRI",
  },
  { CODE: "081", DESCRIPTION: "AGRICULTURE - MIXED", XEMG_CODE: "AGRI" },
  {
    CODE: "091",
    DESCRIPTION: "AGRICULTURE - FORESTRY,HUNTING & FISHING",
    XEMG_CODE: "AGRI",
  },
  {
    CODE: "101",
    DESCRIPTION: "MINING - PUBLIC CORPORATIONS",
    XEMG_CODE: "MINI",
  },
  {
    CODE: "111",
    DESCRIPTION: "MINING - GOLD & URANIUM MINING",
    XEMG_CODE: "MINI",
  },
  { CODE: "121", DESCRIPTION: "MINING - COAL MINING", XEMG_CODE: "MINI" },
  {
    CODE: "131",
    DESCRIPTION: "MINING - DIAMOND MINING",
    XEMG_CODE: "MINI",
  },
  { CODE: "141", DESCRIPTION: "MINING - NATURAL OIL & GAS", XEMG_CODE: "MINI" },
  {
    CODE: "151",
    DESCRIPTION: "MINING - OTHER MINING",
    XEMG_CODE: "MINI",
  },
  {
    CODE: "161",
    DESCRIPTION: "MINING - QUARRYING AND SALT WORKS",
    XEMG_CODE: "MINI",
  },
  {
    CODE: "201",
    DESCRIPTION: "MANUF - PUBLIC CORPORATIONS",
    XEMG_CODE: "MANU",
  },
  { CODE: "211", DESCRIPTION: "MANUF - FOOD", XEMG_CODE: "MANU" },
  {
    CODE: "221",
    DESCRIPTION: "MANUF - BEVERAGES",
    XEMG_CODE: "MANU",
  },
  { CODE: "231", DESCRIPTION: "MANUF - TOBACCO", XEMG_CODE: "MANU" },
  {
    CODE: "241",
    DESCRIPTION: "MANUF - TEXTILES AND FABRICS",
    XEMG_CODE: "MANU",
  },
  {
    CODE: "251",
    DESCRIPTION: "MANUF - FOOTWEAR,OTHER WEARING APPERAL",
    XEMG_CODE: "MANU",
  },
  {
    CODE: "261",
    DESCRIPTION: "MANUF - WOOD AND CORK",
    XEMG_CODE: "MANU",
  },
  {
    CODE: "271",
    DESCRIPTION: "MANUF - FURNITURE & FIXTURES",
    XEMG_CODE: "MANU",
  },
  {
    CODE: "281",
    DESCRIPTION: "MANUF - PAPER AND PAPER PRODUCTS",
    XEMG_CODE: "MANU",
  },
  {
    CODE: "291",
    DESCRIPTION: "MANUF - PRINTING,PUBLISHING,ALLIED INDST",
    XEMG_CODE: "MANU",
  },
  {
    CODE: "301",
    DESCRIPTION: "MANUF - LEATHER & RUBBER PRODUCT",
    XEMG_CODE: "MANU",
  },
  {
    CODE: "311",
    DESCRIPTION: "MANUF - CHEMICALS & CHEMICAL PRODUCTS",
    XEMG_CODE: "MANU",
  },
  { CODE: "321", DESCRIPTION: "MANUF - PETROLEUM & COAL", XEMG_CODE: "MANU" },
  {
    CODE: "331",
    DESCRIPTION: "MANUF - NON-METALLIC PRODUCTS",
    XEMG_CODE: "MANU",
  },
  {
    CODE: "341",
    DESCRIPTION: "MANUF - BASIC METAL INDUSTRIES",
    XEMG_CODE: "MANU",
  },
  {
    CODE: "351",
    DESCRIPTION: "MANUF - METAL PRODUCTS",
    XEMG_CODE: "MANU",
  },
  { CODE: "361", DESCRIPTION: "MANUF - MACHINERY", XEMG_CODE: "MANU" },
  {
    CODE: "371",
    DESCRIPTION: "MANUF - ELECTRICAL MACHINERY",
    XEMG_CODE: "MANU",
  },
  {
    CODE: "381",
    DESCRIPTION: "MANUF - TRANSPORT EQUIPMENT",
    XEMG_CODE: "MANU",
  },
  {
    CODE: "391",
    DESCRIPTION: "MANUF - OTHER MANUFACTURING",
    XEMG_CODE: "MANU",
  },
  { CODE: "451", DESCRIPTION: "BUILDING & CONSTRUCTION", XEMG_CODE: "BUILD" },
  {
    CODE: "471",
    DESCRIPTION: "ELECTRICITY,GAS & WATER",
    XEMG_CODE: "ELEC",
  },
  { CODE: "472", DESCRIPTION: "ESCOM", XEMG_CODE: "ELEC" },
  {
    CODE: "491",
    DESCRIPTION: "TRANSPORT/STORAGE/COMMUNICATION",
    XEMG_CODE: "TRAN",
  },
  { CODE: "492", DESCRIPTION: "TELKOM", XEMG_CODE: "ELEC" },
  {
    CODE: "501",
    DESCRIPTION: "COMMERCE - PUBLIC CORPORATIONS",
    XEMG_CODE: "COMM",
  },
  {
    CODE: "511",
    DESCRIPTION: "COMMERCE - AGRICULTURAL CO-OPS",
    XEMG_CODE: "COMM",
  },
  {
    CODE: "521",
    DESCRIPTION: "COMMERCE - CONSUMERS CO-OPERATIVES",
    XEMG_CODE: "COMM",
  },
  {
    CODE: "531",
    DESCRIPTION: "COMMERCE - AGRICULTURAL PRODUCE MERCHANT",
    XEMG_CODE: "COMM",
  },
  { CODE: "541", DESCRIPTION: "COMMERCE - MOTOR TRADE", XEMG_CODE: "COMM" },
  {
    CODE: "551",
    DESCRIPTION: "COMMERCE - WHOLESALERS",
    XEMG_CODE: "COMM",
  },
  { CODE: "561", DESCRIPTION: "COMMERCE - RETAILERS", XEMG_CODE: "COMM" },
  {
    CODE: "571",
    DESCRIPTION: "COMMERCE - AGENTS AND AUCTIONEERS",
    XEMG_CODE: "COMM",
  },
  {
    CODE: "601",
    DESCRIPTION: "FINANCIAL - PUBLIC COMPANIES",
    XEMG_CODE: "FIN",
  },
  {
    CODE: "611",
    DESCRIPTION: "FINANCIAL - DISCOUNT HOUSES",
    XEMG_CODE: "FIN",
  },
  { CODE: "621", DESCRIPTION: "FINANCIAL - OTHER BANKING", XEMG_CODE: "FIN" },
  {
    CODE: "631",
    DESCRIPTION: "FINANCIAL - S.A BANKING",
    XEMG_CODE: "FIN",
  },
  {
    CODE: "641",
    DESCRIPTION: "FINANCIAL - BUILDING SOCIETY",
    XEMG_CODE: "FIN",
  },
  {
    CODE: "651",
    DESCRIPTION: "FINANCIAL - LONG TERM INSURERS",
    XEMG_CODE: "FIN",
  },
  {
    CODE: "661",
    DESCRIPTION: "FINANCIAL - SHORT TERM INSURERS",
    XEMG_CODE: "FIN",
  },
  {
    CODE: "671",
    DESCRIPTION: "FINANCIAL - INVESTMENT COMPANY",
    XEMG_CODE: "FIN",
  },
  {
    CODE: "681",
    DESCRIPTION: "FINANCIAL - MANUFAC AND COMMERCE",
    XEMG_CODE: "FIN",
  },
  {
    CODE: "691",
    DESCRIPTION: "FINANCIAL - OTHER FINANCIAL INSTITUTIONS",
    XEMG_CODE: "FIN",
  },
  {
    CODE: "701",
    DESCRIPTION: "FINANCIAL - PENSION / PROVIDENT FUNDS",
    XEMG_CODE: "FIN",
  },
  {
    CODE: "711",
    DESCRIPTION: "FINANCIAL - HIRE-PURCHASE",
    XEMG_CODE: "FIN",
  },
  { CODE: "721", DESCRIPTION: "FINANCIAL - SECURITY", XEMG_CODE: "FIN" },
  {
    CODE: "731",
    DESCRIPTION: "FINANCIAL - REAL ESTATE",
    XEMG_CODE: "FIN",
  },
  {
    CODE: "741",
    DESCRIPTION: "FINANCIAL - OTHER FINANCIAL SERVICES",
    XEMG_CODE: "FIN",
  },
  {
    CODE: "811",
    DESCRIPTION: "EDUCATIONAL SERVICES - PRIVATE",
    XEMG_CODE: "EDU",
  },
  { CODE: "821", DESCRIPTION: "MEDICAL SERVICES - PRIVATE", XEMG_CODE: "MED" },
  {
    CODE: "831",
    DESCRIPTION: "COMMUNITY SERVICE",
    XEMG_CODE: "NPO",
  },
  { CODE: "841", DESCRIPTION: "BUSINESS SERVICES", XEMG_CODE: "BUS" },
  {
    CODE: "851",
    DESCRIPTION: "ENTERTAINMENT/SPORTS/RECREATION",
    XEMG_CODE: "ENT",
  },
  { CODE: "861", DESCRIPTION: "PERSONAL SERVICES", XEMG_CODE: "ENT" },
  {
    CODE: "913",
    DESCRIPTION: "GOVT - CENTRAL",
    XEMG_CODE: "NPO",
  },
  { CODE: "922", DESCRIPTION: "GOVT - HOSPITALS", XEMG_CODE: "NPO" },
  {
    CODE: "923",
    DESCRIPTION: "GOVT - TRANSNET",
    XEMG_CODE: "NPO",
  },
  { CODE: "924", DESCRIPTION: "GOVT - JUSTICE", XEMG_CODE: "NPO" },
  {
    CODE: "925",
    DESCRIPTION: "GOVT - MILITARY (ARMED FORCES/AIR/NAVY",
    XEMG_CODE: "NPO",
  },
  { CODE: "926", DESCRIPTION: "GOVT - POSTOFFICE", XEMG_CODE: "NPO" },
  {
    CODE: "933",
    DESCRIPTION: "GOVT - SAA",
    XEMG_CODE: "NPO",
  },
  { CODE: "943", DESCRIPTION: "GOVT - SPOORNET", XEMG_CODE: "NPO" },
  {
    CODE: "953",
    DESCRIPTION: "GOVT - PORTNET",
    XEMG_CODE: "NPO",
  },
  { CODE: "963", DESCRIPTION: "GOVT - PROVINCIAL", XEMG_CODE: "NPO" },
  {
    CODE: "973",
    DESCRIPTION: "GOVT - RECEIVER",
    XEMG_CODE: "NPO",
  },
  { CODE: "983", DESCRIPTION: "GOVT - POLICE", XEMG_CODE: "NPO" },
  {
    CODE: "993",
    DESCRIPTION: "GOVT - SABC",
    XEMG_CODE: "NPO",
  },
  { CODE: "UNK", DESCRIPTION: "UNKNOWN", XEMG_CODE: "OTHER" },
];

export const industryType = [
  {
    value: "",
    label: "Please select...",
  },
  {
    value: "OTHER",
    label: "OTHER",
  },
  {
    value: "TRANSPORT",
    label: "TRANSPORT",
  },
  {
    value: "MINING",
    label: "MINING",
  },
  {
    value: "NPO/GOV",
    label: "NPO/GOV",
  },
  {
    value: "MANUFACTURING",
    label: "MANUFACTURING",
  },
  {
    value: "MEDICAL",
    label: "MEDICAL",
  },
  {
    value: "ENTERTAINMENT",
    label: "ENTERTAINMENT",
  },
  {
    value: "FINANCIAL",
    label: "FINANCIAL",
  },
  {
    value: "EDUCATION",
    label: "EDUCATION",
  },
  {
    value: "ELECTRIC/TELECOMS",
    label: "ELECTRIC/TELECOMS",
  },
  {
    value: "BUSINESS SERVICES",
    label: "BUSINESS SERVICES",
  },
  {
    value: "COMMERCE",
    label: "COMMERCE",
  },
  {
    value: "AGRICULTURE",
    label: "AGRICULTURE",
  },
  {
    value: "BUILDING",
    label: "BUILDING",
  },
];

export const financialInstitutions = [
  { text: "STANDARD BANK", value: "0" },
  {
    text: "ABSA BANK",
    value: "1",
  },
  { text: "CITIBANK N.A.", value: "10" },
  { text: "NEDBANK SWAZILAND", value: "11" },
  {
    text: "PEP BANK (NEDBANK)",
    value: "15",
  },
  { text: "BOE BANK (NEDBANK)", value: "16" },
  { text: "MERCANTILE BANK", value: "17" },
  {
    text: "NBS (NEDBANK)",
    value: "20",
  },
  { text: "STANDARD CHARTERED BANK", value: "21" },
  {
    text: "HBZ BANK",
    value: "22",
  },
  { text: "INVESTEC BANK LIMITED", value: "23" },
  {
    text: "SA POST OFFICE",
    value: "24",
  },
  { text: "STANDARD BANK SWAZILAND", value: "25" },
  {
    text: "U BANK (TEBA)",
    value: "26",
  },
  { text: "HABIB OVERSEAS BANK", value: "28" },
  {
    text: "LESOTHO BANK",
    value: "29",
  },
  { text: "FIRST NATIONAL BANK", value: "3" },
  {
    text: "ITHALA(STANDARD BANK)",
    value: "33",
  },
  { text: "UNIBANK LIMITED", value: "35" },
  { text: "RESERVE BANK", value: "36" },
  {
    text: "S.A. BANK OF ATHENS",
    value: "37",
  },
  { text: "NEDBANK LESOTHO", value: "38" },
  {
    text: "FBC FIDELITY BANK (NEDBANK)",
    value: "39",
  },
  { text: "AFRICAN BANK", value: "4" },
  { text: "GRINDROD BANK", value: "43" },
  {
    text: "CAPITEC BANK",
    value: "44",
  },
  { text: "NEDBANK NAMIBIA", value: "51" },
  { text: "BIDVEST BANK", value: "52" },
  {
    text: "WESBANK",
    value: "54",
  },
  {
    text: "TOYOTA FINANCIAL SERVICES",
    value: "55",
  },
  {
    text: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED",
    value: "56",
  },
  {
    text: "STATE BANK OF INDIA",
    value: "57",
  },
  { text: "VBS MUTUAL BANK", value: "58" },
  {
    text: "ALBARAKA",
    value: "59",
  },
  { text: "STANDARD LESOTHO BANK LIMITED", value: "6" },
  {
    text: "JP MORGAN CHASE BANK N.A",
    value: "60",
  },
  { text: "FINBOND MUTUAL BANK", value: "61" },
  {
    text: "SASFIN BANK LIMITED",
    value: "62",
  },
  { text: "BNP PARIBAS", value: "63" },
  {
    text: "STANDARD BANK NAMIBIA",
    value: "64",
  },
  { text: "VOLKSWAGEN FINANCIAL SERVICES", value: "65" },
  { text: "NEDBANK", value: "7" },
  {
    text: "OTHER",
    value: "99",
  },
];
