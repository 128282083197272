import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, postApiReq, postReq } from "@utils/ApiHandler";
import { toast } from "react-toastify";

export const getVehicleData = createAsyncThunk(
  "vehicleList/getVehicleData",
  async ({
    group,
    page,
    fuel,
    color,
    nameplate,
    engine,
    province,
    derivative,
    sort,
  }) => {
    let data = new FormData();
    data.append("group", group);
    data.append("nameplate", JSON.stringify(nameplate));
    data.append("page", page);
    data.append("fuel", JSON.stringify(fuel));
    data.append("color", JSON.stringify(color));
    data.append("engine", JSON.stringify(engine));
    data.append("province", JSON.stringify(province));
    data.append("derivative", JSON.stringify(derivative));
    data.append("sort", sort === "Price_High" ? "desc" : "asc");
    data.append("brand_code", "LR");
    data.append("carType", "used");
    try {
      const response = await postReq("nameplatelistfinal", data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getFilterData = createAsyncThunk(
  "filterList/getFilterData",
  async ({ name }) => {
    try {
      const response = await getReq(`searchparams/LR?nameplate=${name}`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getVehicleDetails = createAsyncThunk(
  "vehicleDetail/getVehicleDetail",
  async (id) => {
    try {
      const response = await getReq(`estimate/details/${id}`);

      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getCarDetails = createAsyncThunk(
  "vehicleDetail/getCarDetails",
  async (id) => {
    try {
      const response = await getReq(`carDetails/${id}`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getSavedVehicles = createAsyncThunk(
  "vehicleDetail/savedVehicle",
  async (userId) => {
    try {
      const response = await getReq(
        `fetchSaveBuild?user_id=${userId}&brand=LR`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getOrderedVehicles = createAsyncThunk(
  "vehicleDetail/orderedVehicle",
  async (userId) => {
    try {
      const response = await getReq(
        `get-build-orders?user_id=${userId}&brand=LR`
      );

      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const submitOrder = createAsyncThunk(
  "vehicleDetail/submitOrder",
  async (obj) => {
    try {
      const response = await postReq(`save-build-orders`, obj);
      return toast.success(response.data.message);
    } catch (error) {
      console.log(error);
    }
  }
);

export const buyNowVehicle = createAsyncThunk(
  "vehicleDetail/buyNow",
  async (obj) => {
    try {
      const response = await postReq(`buyVehicleOptions`, obj);

      if (response.data.status == 0) {
        return toast.error(response.data.message);
      } else {
        return toast.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const preApprovalVehicle = createAsyncThunk(
  "vehicleDetail/pre-approve",
  async (obj) => {
    try {
      const response = await postApiReq(`pre-approval`, obj);

      if (response.data.status == 0) {
        return toast.error(response.data.message);
      } else {
        return toast.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
);
const initialState = {
  data: [],
  isLoading: true,
  filterData: [],
  vehicleDetailsData: [],
  carData: [],
  savedVehicles: [],
  orderedVehicles: [],
  buyVehicle: "",
};

export const vehicleSlice = createSlice({
  name: "vehicleList",
  initialState,
  reducers: {
    increment: (state) => {
      state.value = state.value + 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVehicleData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVehicleData.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getVehicleData.rejected, (state, action) => {
      state.isLoading = false;
      state.data = [];
    });
    // filter data
    builder.addCase(getFilterData.pending, (state) => {
      // state.isLoading = false;
    });
    builder.addCase(getFilterData.fulfilled, (state, action) => {
      state.filterData = action.payload;
      // state.isLoading = false;
    });
    builder.addCase(getFilterData.rejected, (state, action) => {
      state.isLoading = false;
      state.data = [];
    });

    // particular vehicle details
    builder.addCase(getVehicleDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVehicleDetails.fulfilled, (state, action) => {
      state.vehicleDetailsData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getVehicleDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.vehicleDetailsData = [];
    });

    // particular vehicle details
    builder.addCase(getCarDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCarDetails.fulfilled, (state, action) => {
      state.carData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getCarDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.carData = [];
    });

    //saved vehicle
    builder.addCase(getSavedVehicles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSavedVehicles.fulfilled, (state, action) => {
      state.savedVehicles = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getSavedVehicles.rejected, (state, action) => {
      state.isLoading = false;
      state.savedVehicles = [];
    });
    //ordered vehicles
    builder.addCase(getOrderedVehicles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrderedVehicles.fulfilled, (state, action) => {
      state.orderedVehicles = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getOrderedVehicles.rejected, (state, action) => {
      state.isLoading = false;
      state.orderedVehicles = [];
    });
    //submit vehicles order
    builder.addCase(submitOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(submitOrder.fulfilled, (state, action) => {
      // state.orderedVehicles = action.payload;
      state.isLoading = false;
    });
    builder.addCase(submitOrder.rejected, (state, action) => {
      state.isLoading = false;
      // state.orderedVehicles = [];
    });
    //buy now vehicles
    builder.addCase(buyNowVehicle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyNowVehicle.fulfilled, (state, action) => {
      // state.orderedVehicles = action.payload;
      state.isLoading = false;
    });
    builder.addCase(buyNowVehicle.rejected, (state, action) => {
      state.isLoading = false;
      // state.orderedVehicles = [];
    });
    //pre-approval vehicles
    builder.addCase(preApprovalVehicle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(preApprovalVehicle.fulfilled, (state, action) => {
      // state.orderedVehicles = action.payload;
      state.isLoading = false;
    });
    builder.addCase(preApprovalVehicle.rejected, (state, action) => {
      state.isLoading = false;
      // state.orderedVehicles = [];
    });
  },
});

export const { increment } = vehicleSlice.actions;

export default vehicleSlice.reducer;
